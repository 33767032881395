import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './english';

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    lng: 'en',
    resources: {
      en: english,
    },
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: [
      'translations', 'message',
    ],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
