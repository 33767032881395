import api from '../../config/API';

const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const helloworld = () => api.get('helloWorld');

export const getFuelReconData = (state, startDate, endDate) => api.get('getFuelData', {
  params: {
    state,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

export const getUnitFuelEvents = (unitName, startDate, endDate) => api.get('getUnitFuelEvents', {
  params: {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    unitName,
  },
}).then((response) => response.data);

export const getUnitFuelSummary = (unitName, startDate, endDate) => api.get('getUnitFuelSummary', {
  params: {
    unitName,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

export const getStateFuelSummary = (state, startDate, endDate) => api.get('getStateFuelSummary', {
  params: {
    state,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

export const getStateFuelDropEventTopN = (state, startDate, endDate, N = 10) => api.get('getStateFuelEventNWorst', {
  params: {
    state,
    N,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    eventType: 'drop',
  },
}).then((response) => response.data);

export const getStateFuelFillEventTopN = (state, startDate, endDate, N = 10) => api.get('getStateFuelEventNWorst', {
  params: {
    state,
    N,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    eventType: 'fill',
  },
}).then((response) => response.data);

export const getHealthIndicators = (startDate, endDate, state = 'ogun', N = 10) => api.get('getUnitsHealthIndicatorInState', {
  params: {
    state,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    N,
  },
}).then((response) => response.data);

export const getUnitHealthIndicatorDeltas = (startDate, endDate, state = 'ogun', N = 10) => api.get('getUnitHealthIndicatorDeltas', {
  params: {
    state,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    N,
  },
}).then((response) => response.data);

export const getUnitHealthIndicatorRange = (unitName, startDate, endDate) => api.get('getHealthIndicatorRangeForUnit', {
  params: {
    unitName,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

export const getUnitEvents = (unitName, startDate, endDate) => api.get('getUnitEvents', {
  params: {
    unitName,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

export const getUnitPredictions = (unitName, startDate, endDate) => api.get('getUnitPredictions', {
  params: {
    unitName,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

export const getStateUnits = (state = 'ogun') => api.get('getStateUnits', {
  params: {
    state,
  },
}).then((response) => response.data).catch((err) => err);

export const getStateList = () => api.get('getStateList', {
  params: {
  },
}).then((response) => response.data);

export const getUnitSummaries = (unitName, startDate, endDate, hiThreshold = window.HEALTHINDICATORTHRESHOLD) => api.get('getUnitSummaries', {
  params: {
    unitName,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    hiThreshold,
  },
}).then((response) => response.data[0]);

export const getStateSummaries = (state = 'ogun', startDate, endDate, hiThreshold = window.HEALTHINDICATORTHRESHOLD) => api.get('getStateSummaries', {
  params: {
    state,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    hiThreshold,
  },
}).then((response) => response.data);

export const getFuelRangeForUnit = (unitName, startDate, endDate) => api.get('getFuelRangeForUnit', {
  params: {
    unitName,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

// Log viewed units
export const logUserUnitView = async (unit) => {
  await api.post('logUserUnitView', {
    unit,
  });
};

// LogUserWidgetFeedback
export const logUserWidgetFeedback = async (elementId, feedback) => {
  await api.post('logUserWidgetFeedback', {
    elementId,
    feedback,
  });
};

export const getStateNTopFuel = (locationState, startDate, endDate, topNCount) => api.get('getStateNTopFuel', {
  params: {
    state: locationState,
    N: topNCount,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  },
}).then((response) => response.data);

// Get recently viewed units
export const getUserUnitsView = async (topN) => {
  try {
    const results = await api.get('getUserUnitsView', {
      params: {
        topN,
      },
    });
    return results;
  } catch (err) {
    return err;
  }
};
