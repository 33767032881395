import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { ROOT as ROOT_PATH, DASHBOARD as DASHBOARD_PATH } from './CONSTANTS';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/NotFound';
import PrivateRoute from './PrivatRoute';

const RouterConfig = () => (
  <Switch>
    <Route exact path={ROOT_PATH} component={Login} />

    <PrivateRoute path={DASHBOARD_PATH} component={Dashboard} />

    {/* 404 page not found */}
    <Route path="*" component={NotFound} />
  </Switch>
);

export default RouterConfig;
