import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import translationKeys from '../../i18n/translationKeys';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
}));

const BigCircularProgress = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          <Typography variant="h5">{t(translationKeys.typography.loadingText)}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default BigCircularProgress;
