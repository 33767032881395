export default {
  typography: {
    welcome: 'Welcome to Cerberus!',
    companyName: '',
    loginText: 'Welcome to Cerberus',
    poweredBy: 'powered by',
    cerberus: 'Cerberus',
    kriterion: 'Kriterion',
    pageNotFound: '404 - Page not found',
    pageNotFoundMessage: "This page doesn't exist. Please head back where you came from or to the home page.",
    dashboard: 'Dashboard',
    filters: 'Filters',
    loadingText: 'Loading...',
    recentViewedUnits: 'Recently Viewed Units',
    noRecentViewedUnits: 'No units have been viewed recently.',
    searchUnits: 'Search for Unit',
    unitName: 'Unit', // can be changed to site or unit, used in many places
    unitState: 'State',
    unitRegion: 'Region',
    unitFuelVendor: 'Fuel Vendor',
    tabHealthInformation: 'Health Information',
    tabFuelInformation: 'Fuel Information',
    noUnitID: 'No Unit ID provided.',
    // Fuel Recon View
    totalFuelConsumption: 'Total Fuel Consumption [L]',
    averageFuelConsumption: 'Average Fuel Consumption [L/day]',
    refuel: 'Refuel [L]',
    fuelDrop: 'Fuel Drop [L]',
    total: 'Total',
    reconciled: 'Reconciled',
    unreconciled: 'Unreconciled',
    fuelComparison: 'Measurement Comparison',
    refuelComparison: 'Refuel Comparison [L]',
    fuelDropComparison: 'Drop Comparison [L]',
    totalFuelDrop: 'Total Fuel Drop',
    totalFuelFill: 'Total Fuel Fill',
    insufficientData: 'Insufficient data for given period',
  },
  button: {
    login: 'Login',
    loginWindows: 'Sign in with Microsoft',
    backToLogin: 'Go back to Login',
    pageNotFoundGoHome: 'Go to homepage',
    updateFilters: 'Update',
    clearFilters: 'Clear',
    viewUnit: 'View Unit',
    submit: 'Submit',
    cancel: 'Cancel',
  },
  label: {
    email: 'Email address',
    password: 'Password',
    startDate: 'Start Date',
    endDate: 'End Date',
    loading: 'Loading...',
    filterState: 'State',
  },
  menu: {
    logout: 'Logout',
    profile: 'Profile',
    dashboard: 'Dashboard',
    overview: 'Overview',
    fuelRecon: 'Fuel Reconciliation',
    unitSearch: 'Unit Search',
    unitView: 'Unit View',
    faq: 'FAQ',
  },
  snackbar: {
    loginFailed: 'Login failed: Invalid username or password.',
    apiErrorUnitHealthIndicator: 'Failed to GET Unit Health Indicator data',
    apiErrorUnitEvent: 'Failed to GET Unit Event data',
    apiErrorUnitPredictionsEvent: 'Failed to GET Unit Predictions Event data',
    apiErrorUnitSummary: 'Failed to GET Unit Summary data',
    apiErrorUnitFuelSummary: 'Failed to GET Unit Fuel Summary data',
    apiErrorUnitFuelEvent: 'Failed to GET Unit Fuel Event data',
    apiErrorStateFuelSummary: 'Failed to GET State Fuel Summary data',
    apiErrorStateFuelRecon: 'Failed to GET State Fuel Reconciliation data',
    apiErrorStateFuelDropEvent: 'Failed to GET State Top Drop Events',
    apiErrorStateFuelFillEvent: 'Failed to GET State Top Refuel Events',
    feedbackLoggingError: 'Error logging your feedback!',
    feedbackLoggingSuccess: 'Thanks for the feedback!',

  },
  appStageInfo: {
    disabledTrial: 'Disable for Ogun Audit trial.',
    appVersionMessage: `Cerberus is currently in version: ${process.env.REACT_APP_STAGE}.`,
    feedbackMessage: 'Providing us with feedback would be appreciated!',
  },
  dialog: {
    feedback: 'Please give a moment to provide us with feedback.',
    comment: 'Add a comment',
    prompt: 'I found this widget:',
    useful: 'Useful',
    unclear: 'Unclear',
    other: 'Other',
    buggy: 'Buggy',
    notRelevant: 'Not relevant',
  },

};
