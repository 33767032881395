import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import HelpIcon from '@material-ui/icons/Help';
import translationKeys from '../i18n/translationKeys';

export const ROOT = '/';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_OVERVIEW = '/dashboard/overview';
export const DASHBOARD_FUELRECON = '/dashboard/fuel_recon';
export const DASHBOARD_UNIT = '/dashboard/unit/';
export const DASHBOARD_UNITS_SEARCH = '/dashboard/units';
export const DASHBOARD_FAQ = '/dashboard/faq';

export const OPS_RANK = 2;
export const TECH_RANK = 1;

export const MENU_ITEMS = {
  overview: {
    textKey: translationKeys.menu.dashboard,
    icon: <DashboardIcon />,
    path: DASHBOARD_OVERVIEW,
  },
  unitSearch:
  {
    textKey: translationKeys.menu.unitSearch,
    icon: <FindInPageIcon />,
    path: DASHBOARD_UNITS_SEARCH,
  },
  fuelRecon:
  {
    textKey: translationKeys.menu.fuelRecon,
    icon: <AssignmentIcon />,
    path: DASHBOARD_FUELRECON,
  },
  faq:
  {
    textKey: translationKeys.menu.faq,
    icon: <HelpIcon />,
    path: DASHBOARD_FAQ,
  },
};
