import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { DASHBOARD_UNIT } from '../../navigation/CONSTANTS';
import LinkButton from '../LinkButton';

const useStyles = makeStyles(() => ({
  cardRoot: {
    width: 116,
    height: 172,
    zIndex: 3,
  },
  cardAction: {
    justifyContent: 'center',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
}));

const FormatValue = (props) => {
  const { loadState, value, valueNominalFn } = props;
  const variant = 'h6';
  if ((!loadState && value) && (valueNominalFn(value))) {
    return (
      <Typography variant={variant}>
        {`${(value * 100).toFixed(2)} %`}
      </Typography>
    );
  } if (!loadState && value) {
    return (
      <Typography color="error" variant={variant}>
        {`${(value * 100).toFixed(2)} %`}
      </Typography>
    );
  }

  return (
    <Typography variant={variant}>
      <Skeleton />
    </Typography>
  );
};

const FormatHICard = (props) => {
  const {
    classes, loadState, itemObj, index, valueKey, valueNominalFn,
  } = props;
  return (
    <Grid item>
      <Card className={classes.cardRoot}>
        <CardContent>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {!loadState && itemObj ? (`#${index + 1} Unit`) : (<Skeleton />)}
          </Typography>
          <Typography variant="h6" component="h2">
            {!loadState && itemObj ? (itemObj.unit_name.toUpperCase()) : (<Skeleton />)}
          </Typography>
          {/* <Typography className={classes.pos} color="textSecondary">
                    {locationState}
                  </Typography> */}
          {!loadState && itemObj ? (<FormatValue loadState={loadState} value={itemObj[valueKey]} valueNominalFn={valueNominalFn} />) : (
            <Typography variant="h6">
              <Skeleton />
            </Typography>
          )}
        </CardContent>
        <CardActions className={classes.cardAction}>
          {!loadState && itemObj ? (
            <LinkButton
              size="small"
              to={`${DASHBOARD_UNIT}${itemObj.unit_name}`}
            >
              Detail View
            </LinkButton>
          ) : (<Skeleton variant="rect" width="70%" />)}
        </CardActions>
      </Card>
    </Grid>
  );
};

const HealthIndicatorsBox = (props) => {
  const {
    series, loadState, valueKey, valueNominalFn,
  } = props;
  const classes = useStyles(props);
  return (
    <>
      <Grid
        container
        spacing={3}
      // justify="space-between"
      >
        {
          series.map((itemObj, index) => (
            <FormatHICard
              key={itemObj?.unit_name || index}
              classes={classes}
              loadState={loadState}
              itemObj={itemObj}
              index={index}
              valueKey={valueKey}
              valueNominalFn={valueNominalFn}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default HealthIndicatorsBox;
