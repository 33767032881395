import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogTitle, IconButton, Typography, DialogContent,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import CloseIcon from '@material-ui/icons/Close';
import { DASHBOARD_UNIT } from '../../navigation/CONSTANTS';
import { formatNumber } from '../../utils/index';
import LinkButton from '../LinkButton';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: 1500,
    height: 800,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const NullUnitsModal = (props) => {
  const classes = useStyles();
  const {
    onClose, nullUnits, open,
  } = props;

  const handleClose = () => {
    onClose(nullUnits);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Unit',
      width: 150,
      type: 'string',
      valueFormatter: (params) => (params.value.toUpperCase() || ''),
      renderCell: ({ value }) => <LinkButton fullWidth variant="contained" color="secondary" to={`${DASHBOARD_UNIT}${value}`}>{`${value}`}</LinkButton>,
    },
    {
      headerName: 'Top Event', field: 'event', flex: 1, type: 'string',
    },
    {
      headerName: 'Top Event % Low Quality Days',
      field: 'missing_percent',
      width: 270,
      type: 'number',
      valueFormatter: (params) => (formatNumber(params.value)),
    },
  ];
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl">
      <DialogTitle id="simple-dialog-title">
        <Typography variant="h6">Sites with Unobtainable Metrics:</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {(nullUnits !== undefined) ? (
        <DialogContent dividers className={classes.dialogContent}>
          <DataGrid rows={nullUnits} columns={columns} pageSize={80} />
        </DialogContent>
      ) : (<></>)}
    </Dialog>
  );
};

export default NullUnitsModal;
