import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogTitle, IconButton, Typography, DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid } from '@material-ui/data-grid';

import { DASHBOARD_UNIT } from '../../navigation/CONSTANTS';
import { formatNumber } from '../../utils/index';
import LinkButton from '../LinkButton';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: 1500,
    height: 800,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const SelectedSitesModal = (props) => {
  const classes = useStyles();
  const {
    onClose, selectedPercentile, open, units,
  } = props;

  const handleClose = () => {
    onClose(selectedPercentile);
  };

  const [displayedUnits, setDisplayedUnits] = useState([]);

  useEffect(() => {
    const selectedUnits = units[selectedPercentile];
    setDisplayedUnits(selectedUnits);
  }, [units, selectedPercentile]);
  const columns = [
    {
      field: 'id',
      headerName: 'Unit',
      width: 150,
      type: 'string',
      valueFormatter: (params) => (params.value.toUpperCase() || ''),
      renderCell: ({ value }) => <LinkButton fullWidth variant="contained" color="secondary" to={`${DASHBOARD_UNIT}${value}`}>{`${value}`}</LinkButton>,
    },
    {
      headerName: 'Power Output [kWh/Day](Nominal)',
      field: 'average_generator_output_kwh',
      width: 300,
      type: 'number',
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      headerName: 'Fuel Consumption [L/Day]',
      field: 'average_fuel_consumption_l',
      width: 300,
      type: 'number',
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      headerName: 'Thermal Efficiency',
      field: 'average_generator_efficiency',
      width: 200,
      type: 'number',
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      headerName: 'Data Completeness',
      field: 'average_data_completeness',
      width: 200,
      type: 'number',
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      headerName: 'Data Quality',
      field: 'average_percent_quality_data',
      width: 200,
      type: 'number',
      valueFormatter: (params) => (formatNumber(params.value)),
    },
  ];
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl">
      <DialogTitle id="simple-dialog-title">
        <Typography variant="h6">Sites in selection:</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {(displayedUnits !== undefined) ? (
        <DialogContent dividers className={classes.dialogContent}>
          <DataGrid rows={displayedUnits} columns={columns} pageSize={100} />
        </DialogContent>
      ) : (<></>)}
    </Dialog>
  );
};

SelectedSitesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedPercentile: PropTypes.number.isRequired,
};

export default SelectedSitesModal;
