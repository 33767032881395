import firebase from '../Firebase';
import {
  MENU_ITEMS, DASHBOARD, DASHBOARD_OVERVIEW, DASHBOARD_UNITS_SEARCH,
} from '../../navigation/CONSTANTS';

export const getLandingPage = () => {
  if (firebase.hasOperationRoles()) {
    return DASHBOARD_OVERVIEW;
  }
  if (firebase.hasTechnicianRoles()) {
    return DASHBOARD_UNITS_SEARCH;
  }
  return DASHBOARD;
};

export const getMenuItems = () => {
  const tempItemsList = [];
  if (firebase.hasOperationRoles()) {
    tempItemsList.push(MENU_ITEMS.overview);
    tempItemsList.push(MENU_ITEMS.unitSearch);
    tempItemsList.push(MENU_ITEMS.fuelRecon);
    tempItemsList.push(MENU_ITEMS.faq);
  } else if (firebase.hasTechnicianRoles()) {
    tempItemsList.push(MENU_ITEMS.unitSearch);
    tempItemsList.push(MENU_ITEMS.faq);
  }
  return tempItemsList;
};

export default { getLandingPage, getMenuItems };
