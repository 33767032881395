import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, List, ListItem, ListItemText, Paper, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const EventsBlock = (props) => {
  const classes = useStyles(props);
  const { eventsData, type } = props;
  return (
    <>
      {
        ((eventsData !== undefined) && eventsData.length > 0) ? (
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.paper}>
              <Typography component="h6" variant="h6">
                {`${type} Events`}
              </Typography>
              <List component="ul" aria-label={`${type} Events`}>
                {eventsData.map((item, i) => (
                  <ListItem key={`${i + 1}${item.type}_${item.timestamp}`}>
                    <ListItemText
                      primary={item.type}
                      secondary={(
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {new Date(item.timestamp).toISOString().slice(0, 10)}
                          </Typography>
                          <br />
                          <Typography
                            component="span"
                            variant="body2"
                          // color="textPrimary"
                          >
                            {item.comment}
                          </Typography>

                        </>
                      )}
                    />
                  </ListItem>
                ))}

              </List>
            </Paper>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.paper}>
                <Typography component="h6" variant="h6">
                  {`${type} Events`}
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  No associated events
                </Typography>
              </Paper>
            </Grid>
          </>
        )
      }
    </>
  );
};

const UnitEvents = (props) => {
  const { eventsData } = props;
  return (
    <>
      <EventsBlock type="Periodic" eventsData={eventsData.Periodic} />
      <EventsBlock type="Corrective" eventsData={eventsData.Corrective} />
      <EventsBlock type="Replacement" eventsData={eventsData.Replacement} />

    </>
  );
};

export default UnitEvents;
