import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import translationKeys from '../../i18n/translationKeys';

const Copyright = (props) => {
  const { t } = props;
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://kriterion.ai/">
        {t(translationKeys.typography.kriterion)}
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
};

export default Copyright;
