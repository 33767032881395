import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Grid, Paper, Typography, Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { DashboardContext } from './context';
import {
  getHealthIndicators, getStateList, getStateSummaries, getUnitHealthIndicatorDeltas,
} from '../../services/Data';
import HealthIndicatorsBox from '../../components/Dashboard/HealthIndicatorsBox';
import SummaryHistogramChart from '../../components/Dashboard/SummaryHistogram';
import SelectedSitesModal from '../../components/Dashboard/SelectedSitesModal';
import { HealthCard, getDelta, NullCard } from '../../components/Dashboard/SummaryCards';
import NullUnitsModal from '../../components/Dashboard/NullUnitsModal';
import { formatNumber } from '../../utils';
import FeedbackPopUp from '../../components/FeedbackPopUp';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  chartPaper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '626px',

  },
  fixedHeight: {
    height: 230,
  },
}));

const Overview = (props) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const {
    startDate, endDate, setLoadState, locationState, loadState, setStateList,
  } = useContext(DashboardContext);
  const topNCount = 10;
  const hiThreshold = window.HEALTHINDICATORTHRESHOLD;
  const [healthIndicators, setHealthIndicators] = useState(new Array(topNCount).fill(undefined));
  const [healthIndicatorDeltas, setHealthIndicatorDeltas] = useState(new Array(topNCount).fill(undefined));
  const [stateSummaries, setStateSummaries] = useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedPercentile, setSelectedPercentile] = React.useState(undefined);
  const [NullmodalOpen, setNullModalOpen] = React.useState(false);

  useEffect(() => {
    setLoadState(true);
    const fetchData = async () => {
      await Promise.all([getHealthIndicators(startDate, endDate, locationState, topNCount).then((result) => {
        setHealthIndicators(result);
      }).catch(() => {
        setHealthIndicators([]);
        enqueueSnackbar('Error getting health indicators', {
          variant: 'error',
        });
      }),
      getUnitHealthIndicatorDeltas(startDate, endDate, locationState, topNCount).then((result) => {
        setHealthIndicatorDeltas(result);
      }).catch(() => {
        setHealthIndicatorDeltas([]);
        enqueueSnackbar('Error getting health indicator deltas', {
          variant: 'error',
        });
      }),
      getStateSummaries(locationState, startDate, endDate, hiThreshold).then((result) => {
        setStateSummaries(result);
      }).catch(() => {
        setStateSummaries([]);
        enqueueSnackbar('Error getting state summary', {
          variant: 'error',
        });
      }),
      getStateList().then((result) => {
        setStateList(result);
      }).catch(() => {
        setStateList([]);
        enqueueSnackbar('Error getting list of states', {
          variant: 'error',
        });
      }),
      ]);
    };
    fetchData().finally(() => setLoadState(false));
  }, [startDate, endDate, locationState]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={6} xl={6}>
        <Grid
          container
          direction="row"
          spacing={3}
        >
          <Grid item xs={12}>

            <FeedbackPopUp
              header="Health Threshold"
              elementId="health_indicator"
              page="overview"
              child={(
                <Tooltip
                  placement="right"
                  title={`These values show the average performance gains of units in the selected fleet which are maintained above the ${formatNumber(hiThreshold * 100, '%')} health indicator threshold as compared to the units below this threshold`}
                >
                  <Typography component="h1" variant="h5" color="primary" gutterBottom>
                    Performance Metrics
                  </Typography>
                </Tooltip>

              )}
            />
            <Grid item xs={6}>
              <Typography component="h3" variant="h6" color="textSecondary" gutterBottom>
                {`Health Indicator Threshold = ${formatNumber(hiThreshold * 100, '%')}`}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
            >
              <Grid item xs={6} md={6} lg={4} xl={4}>
                <HealthCard
                  cardType="normal"
                  efficiency={stateSummaries?.metrics?.normal?.efficiency_normal || null}
                  fuelConsumption={stateSummaries?.metrics?.normal?.consumption_normal_litres || null}
                  powerOutput={stateSummaries?.metrics?.normal?.output_normal_kwh || null}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={4} xl={4}>
                <HealthCard
                  cardType="abnormal"
                  efficiency={stateSummaries?.metrics?.abnormal?.efficiency_abnormal}
                  fuelConsumption={stateSummaries?.metrics?.abnormal?.consumption_abnormal_litres}
                  powerOutput={stateSummaries?.metrics?.abnormal?.output_abnormal_kwh}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={4} xl={4}>
                <HealthCard
                  cardType="delta"
                  efficiency={getDelta(stateSummaries?.metrics?.normal?.efficiency_normal, stateSummaries?.metrics?.abnormal?.efficiency_abnormal)}
                  fuelConsumption={getDelta(stateSummaries?.metrics?.normal?.consumption_normal_litres, stateSummaries?.metrics?.abnormal?.consumption_abnormal_litres)}
                  powerOutput={getDelta(stateSummaries?.metrics?.normal?.output_normal_kwh, stateSummaries?.metrics?.abnormal?.output_abnormal_kwh)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <FeedbackPopUp
                header="Up Coming Events"
                elementId="up_coming_events"
                page="overview"
                child={(
                  <Tooltip
                    title="All short term incident events predicted by Cerberus are shown here. (Will be rolled out after the beta phase)"
                    placement="right"
                  >
                    <Typography component="h1" variant="h5" color="primary" gutterBottom>
                      Up Coming Events (Placeholder)
                    </Typography>
                  </Tooltip>
)}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                List of events, order by date.
                <br />
                Mean time to repair
                <br />
                Point of contact
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={6} xl={6}>

            <FeedbackPopUp
              header="Unobtainable Metrics"
              elementId="Unobtainable_metrics"
              page="overview"
              child={(
                <Tooltip
                  title="These are all the units for which not enough data is available to create any useful metrics"
                  placement="right"
                >
                  <Typography component="h1" variant="h5" color="primary" gutterBottom>
                    Unobtainable Metrics
                  </Typography>
                </Tooltip>
)}
            />

            <NullCard
              numberOfUnits={stateSummaries?.metrics?.low_quality?.count || null}
              setNullModalOpen={setNullModalOpen}
            />
            <NullUnitsModal
              onClose={() => setNullModalOpen(false)}
              nullUnits={stateSummaries?.metrics?.low_quality?.units}
              open={NullmodalOpen}
            />
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={6} xl={6}>

        <FeedbackPopUp
          header="Health Indicator Histogram"
          elementId="health_indicator_histogram"
          page="overview"
          child={(
            <Tooltip
              title="Breakdown of fleet performance by health indicator (averaged over the given search period). Click on the bars to drill down to the unit level."
              placement="right"
            >
              <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Average Health Breakdown
              </Typography>
            </Tooltip>
)}
        />

        <Paper className={classes.chartPaper}>
          <Grid item xs={12}>
            <SummaryHistogramChart
              summaryHistogramBins={stateSummaries?.histogram?.catagories || null}
              summaryHistogramData={stateSummaries?.histogram || { bin_counts: [] }}
              setModalOpen={setModalOpen}
              setSelectedPercentile={setSelectedPercentile}
            />
          </Grid>
          {(stateSummaries.histogram !== undefined && stateSummaries.histogram.units !== [] && selectedPercentile !== undefined) ? (
            <SelectedSitesModal
              selectedPercentile={selectedPercentile}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              units={stateSummaries.histogram.units}
            />
          ) : (<></>)}
        </Paper>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>

        <FeedbackPopUp
          header="Top Changes in HI List"
          elementId="top_health_indicator_list"
          page="overview"
          child={(
            <Tooltip
              title="The biggest drops in Health Indicator values for the fleet in the given search period"
              placement="right"
            >
              <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Top
                {' '}
                {topNCount}
                {' '}
                Changes in Health Indicator
              </Typography>
            </Tooltip>
)}
        />
        <HealthIndicatorsBox
          series={healthIndicatorDeltas}
          locationState={locationState}
          loadState={loadState}
          valueNominalFn={(value) => (value < 0.5)}
          valueKey="delta"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <FeedbackPopUp
          header="Worst HI List"
          elementId="worst_health_indicators_list"
          page="overview"
          child={(
            <Typography component="h1" variant="h5" color="primary" gutterBottom>
              Top
              {' '}
              {topNCount}
              {' '}
              Worst Units by Health Indicator
            </Typography>
)}
        />
        <HealthIndicatorsBox
          series={healthIndicators}
          locationState={locationState}
          loadState={loadState}
          valueNominalFn={(value) => (value > 0.5)}
          valueKey="health_indicator_value"
        />

      </Grid>
    </Grid>
  );
};
export default Overview;
