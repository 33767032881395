import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Switch, useLocation, Redirect,
} from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar, Button, Container, IconButton, Menu, MenuItem,
  Toolbar, Typography,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import translationKeys from '../../i18n/translationKeys';
import firebase from '../../config/Firebase';
import { RankedPrivateRoute } from '../../navigation/PrivatRoute';
import { getLandingPage } from '../../config/User';
import { drawerWidth } from '../../styles/CONSTANTS';
import Overview from './Overview';
import UnitView from './UnitView/index';
import FuelReconView from './FuelReconView';
import FaqView from './FaqView';
import PageDrawer from './PageDrawer';
import { DashboardContext } from './context';
import UnitSearch from './UnitSearch';
import FancyLinearProgress from '../../components/FancyLinearProgress';
import FilterBar from '../../components/FilterBar';
import {
  ROOT, DASHBOARD, DASHBOARD_OVERVIEW, DASHBOARD_FUELRECON, DASHBOARD_UNIT, DASHBOARD_UNITS_SEARCH, DASHBOARD_FAQ, OPS_RANK, TECH_RANK,
} from '../../navigation/CONSTANTS';
import NotFound from '../NotFound';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(3),
  },
  menuButtonHidden: {
    display: 'none',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  filterBar: {
    flexGrow: 1,
  },
  mainContent: {
    overflow: 'auto',
    height: `calc(100vh - ${theme.spacing(9)}px)`,
  },

  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  progressBar: {
    height: theme.spacing(1),
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  progressBarSpacer: {
    padding: theme.spacing(0.5),
  },
}));

const AppBarWithFilter = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [appBarTitle, setAppBarTitle] = useState(t(translationKeys.menu.dashboard));
  const {
    loadState, locationState,
    startDate, endDate, stateList, setLocationState, setStartDate, setEndDate,
    anchorEl, setAnchorEl, drawerOpen, setDrawerOpen,
    history,
  } = props;

  async function logout() {
    await firebase.logout();
    history.push({ ROOT });
  }

  const routerLocation = useLocation();
  const { pathname } = routerLocation;

  useEffect(() => {
    let tempAppBarTitle = '';
    switch (pathname) {
      case DASHBOARD_OVERVIEW:
        tempAppBarTitle = t(translationKeys.menu.overview);
        break;
      case DASHBOARD_UNITS_SEARCH:
        tempAppBarTitle = t(translationKeys.menu.unitSearch);
        break;
      case DASHBOARD_FUELRECON:
        tempAppBarTitle = t(translationKeys.menu.fuelRecon);
        break;
      default:
        // Take care of unit view which has the unit id in URI
        if (pathname.includes(DASHBOARD_UNIT)) {
          tempAppBarTitle = t(translationKeys.menu.unitView);
        } else {
          tempAppBarTitle = '';
        }
        break;
    }
    setAppBarTitle(tempAppBarTitle);
  }, [pathname]);

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setDrawerOpen(true)}
          className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap>
          {appBarTitle}
        </Typography>
        <div className={classes.filterBar}>
          <FilterBar
            locationState={locationState}
            setLocationState={setLocationState}
            stateList={stateList}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            loadState={loadState}
          />
        </div>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          size="medium"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          color="secondary"
        >
          <AccountCircle />
          <Typography color="secondary">{firebase.getCurrentUsername()}</Typography>
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={logout}>{t(translationKeys.menu.logout)}</MenuItem>
        </Menu>
      </Toolbar>
      <div className={classes.progressBar}>{loadState ? (<FancyLinearProgress />) : (<></>)}</div>
    </AppBar>
  );
};

const Dashboard = (props) => {
  const classes = useStyles(props);
  const { history } = props;
  const [loadState, setLoadState] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(new Date(window.START_DATE));
  const [endDate, setEndDate] = useState(new Date(window.END_DATE));
  const [locationState, setLocationState] = useState(window.LOCATION_STATE);
  const [stateList, setStateList] = useState([]);

  const [landingPage, setLandingPage] = useState(DASHBOARD);

  useEffect(() => {
    setLandingPage(getLandingPage());
  }, []);

  return (
    <div className={classes.root}>
      <DashboardContext.Provider
        value={{
          startDate,
          endDate,
          setStartDate,
          setEndDate,
          drawerOpen,
          handleDrawerClose,
          loadState,
          setLoadState,
          locationState,
          setLocationState,
          stateList,
          setStateList,
        }}
      >
        <AppBarWithFilter
          loadState={loadState}
          locationState={locationState}
          startDate={startDate}
          endDate={endDate}
          setLocationState={setLocationState}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          stateList={stateList}
          setAnchorEl={setAnchorEl}
          setDrawerOpen={setDrawerOpen}
          history={history}
          drawerOpen={drawerOpen}
          anchorEl={anchorEl}
        />
        <PageDrawer />
        <div className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.progressBarSpacer} />
          <main className={classes.mainContent}>
            <Container maxWidth="xl" className={classes.container}>
              <Switch>
                <Redirect exact from={DASHBOARD} to={landingPage} />
                <RankedPrivateRoute exact path={DASHBOARD_OVERVIEW} component={Overview} rank={OPS_RANK} />
                <RankedPrivateRoute path={`${DASHBOARD_UNIT}:unitName`} component={UnitView} rank={TECH_RANK} />
                <Redirect exact from={DASHBOARD_UNIT} to={DASHBOARD_UNITS_SEARCH} />
                <RankedPrivateRoute exact path={DASHBOARD_UNITS_SEARCH} component={UnitSearch} rank={TECH_RANK} />
                <RankedPrivateRoute exact path={DASHBOARD_FUELRECON} component={FuelReconView} rank={OPS_RANK} />
                <RankedPrivateRoute exact path={DASHBOARD_FAQ} component={FaqView} rank={TECH_RANK} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Container>
          </main>
        </div>
      </DashboardContext.Provider>
    </div>
  );
};

export default Dashboard;
