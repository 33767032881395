import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firebase-firestore';
import 'firebase/auth';
import 'firebase/performance';
import axios from 'axios';
import { OPS_RANK, TECH_RANK } from '../../navigation/CONSTANTS';

const API_BASE = window.REACT_APP_API || '';

const config = {
  // TODO: Move config values to ENV variables
  apiKey: 'AIzaSyD0UBJ1cERQGmitVIS3hZwG74WTVE-K-OQ',
  authDomain: 'atc-basestation.firebaseapp.com',
  projectId: 'atc-basestation',
  messagingSenderId: '341581590445',
  appId: '1:341581590445:web:6d31fce306053f6d17fb24',
  measurementId: 'G-K22GVP8MDE',
};

// TODO: Refactor as auth service, do async and wait within service

class Firebase {
  constructor() {
    app.initializeApp(config);
    app.analytics();
    this.perf = app.performance();
    this.auth = app.auth();
    this.db = app.firestore();

    this.microsoftProvider = new app.auth.OAuthProvider('microsoft.com');
    this.microsoftProvider.setCustomParameters({
      tenant: '4b9d21d4-5ce6-4db6-bce6-cfcd1920afbc',
    });
    this.microsoftProvider.addScope('GroupMember.Read.All');
  }

  async loginWithMicrosoft() {
    try {
      const result = await this.auth.signInWithPopup(this.microsoftProvider);
      const { accessToken } = result.credential;
      await this.getUserRoles(accessToken);
      this.refreshRoles(true);
      return { message: 'success' };
    } catch (error) {
      return { message: 'failure' };
    }
  }

  refreshRoles(force = false) {
    if (this.auth.currentUser) {
      return this.auth.currentUser.getIdTokenResult(force)
        .then((results) => {
          this.auth.currentUser.Technicians = results.claims.Technicians;
          this.auth.currentUser.Testers = results.claims.Testers;
          this.auth.currentUser.Operations = results.claims.Operations;
          return true;
        })
        .catch(() => false);
    }
    return false;
  }

  logout() {
    return this.auth.signOut();
  }

  isInitialised() {
    return new Promise((resolve) => this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.refreshRoles();
      }
      resolve('Initialised');
    }));
  }

  getCurrentUsername() {
    return this.auth.currentUser && this.auth.currentUser.displayName;
  }

  hasTechnicianRoles() {
    return this.auth.currentUser && this.auth.currentUser.Technicians;
  }

  hasOperationRoles() {
    return this.auth.currentUser && this.auth.currentUser.Operations;
  }

  hasTesterRoles() {
    return this.auth.currentUser && this.auth.currentUser.Testers;
  }

  getCurrentEmail() {
    return this.auth.currentUser && this.auth.currentUser.email;
  }

  getCurrentUserId() {
    return this.auth.currentUser && this.auth.currentUser.uid;
  }

  getRank() {
    if (this.auth.currentUser) {
      if (this.auth.currentUser.Operations) {
        return OPS_RANK;
      }
      if (this.auth.currentUser.Technicians) {
        return TECH_RANK;
      }
    }
    return 0;
  }

  getIdToken() {
    return this.auth.currentUser && this.auth.currentUser.getIdToken(true);
  }

  async getUserRoles(msat) {
    const token = await this.getIdToken();
    const groupConfig = {
      method: 'post',
      url: `${API_BASE}getUserRoles`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        msat,
      },
    };
    try {
      const response = await axios(groupConfig);
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default new Firebase();
