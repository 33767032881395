export const formatNumber = (value, unitType = '', reduce = true) => {
  if (value === null || value === undefined) {
    return '--';
  }
  let newValue = value;
  let symbol = '';
  if (reduce) {
    if (Math.abs(value) >= 1_000_000) {
      newValue = value / 1_000_000;
      symbol = 'M';
    } else if (Math.abs(value) >= 1_000) {
      newValue = value / 1_000;
      symbol = 'K';
    }
    return `${newValue.toFixed(2)} ${symbol} ${unitType}`.trim();
  }
  return `${newValue.toFixed(2)} ${unitType}`.trim();
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export default { formatNumber, formatDate };
