/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider, Grid, Tabs, Tab, Typography,
} from '@material-ui/core';
import moment from 'moment';
import HealthTab from './HealthTab';
import FuelTab from './FuelTab';
import SummaryCards from '../../../components/Dashboard/SummaryCards';
import { DashboardContext } from '../context';
import {
  getUnitEvents, getUnitHealthIndicatorRange, getUnitPredictions, logUserUnitView,
  getUnitSummaries, getUnitFuelEvents, getUnitFuelSummary, getFuelRangeForUnit,
} from '../../../services/Data';
import BigCircularProgress from '../../../components/BigCircularProgress';
import translationKeys from '../../../i18n/translationKeys';

const useStyles = makeStyles((theme) => ({
  box: {
    paddingTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const TabPanel = (props) => {
  const classes = useStyles(props);
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`unit-view-tabpanel-${index}`}
      aria-labelledby={`unit-view-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={classes.box}>{children}</div>
      )}
    </div>
  );
};
const a11yProps = (index) => ({
  id: `unit-view-tab-${index}`,
  'aria-controls': `unit-view-tabpanel-${index}`,
});

const UnitView = (props) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { match } = props;
  const { unitName } = match.params;
  const { startDate, endDate, setLoadState } = useContext(DashboardContext);
  const [healthIndicatorData, setHealthIndicatorData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [predictionsData, setPredictionsData] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [fuelSummaryData, setFuelSummaryData] = useState();
  const [fuelUnitData, setFuelUnitData] = useState();
  const [fuelEvents, setFuelEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setLoadState(true);
    if (unitName !== '') {
      setIsLoading(true);
      const fetchData = async () => {
        await Promise.all(
          [
            logUserUnitView({ unit_name: unitName }),
            getUnitHealthIndicatorRange(unitName, startDate, endDate)
              .then((results) => setHealthIndicatorData(results))
              .catch(() => {
                setHealthIndicatorData([]);
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitHealthIndicator), {
                  variant: 'error',
                });
              }),
            getUnitEvents(unitName, startDate, endDate).then((results) => setEventsData(results))
              .catch(() => {
                setEventsData([]);
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitEvent), {
                  variant: 'error',
                });
              }),
            getUnitPredictions(unitName, startDate, endDate).then((results) => setPredictionsData(results))
              .catch(() => {
                setPredictionsData([]);
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitPredictionsEvent), {
                  variant: 'error',
                });
              }),
            getUnitSummaries(unitName, startDate, endDate).then((results) => setSummaryData(results))
              .catch(() => {
                setSummaryData();
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitSummary), {
                  variant: 'error',
                });
              }),
            getUnitFuelSummary(unitName, startDate, endDate).then((results) => setFuelSummaryData(results))
              .catch(() => {
                setFuelSummaryData();
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitFuelSummary), {
                  variant: 'error',
                });
              }),
            getFuelRangeForUnit(unitName, startDate, endDate).then((results) => setFuelUnitData(results))
              .catch(() => {
                setFuelUnitData([]);
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitFuelSummary), {
                  variant: 'error',
                });
              }),
            getFuelRangeForUnit(unitName, startDate, endDate).then((results) => setFuelUnitData(results))
              .catch(() => {
                setFuelUnitData([]);
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitFuelSummary), {
                  variant: 'error',
                });
              }),
            getUnitFuelEvents(unitName, startDate, endDate)
              .then((results) => setFuelEvents(results.map((item, index) => {
                item.id = index;
                item.date = moment(item.date).format(window.DATETIMEFORMAT);
                return item;
              })))
              .catch(() => {
                setFuelEvents([]);
                enqueueSnackbar(t(translationKeys.snackbar.apiErrorUnitFuelEvent), {
                  variant: 'error',
                });
              }),
          ],
        );
      };
      fetchData().finally(() => { setIsLoading(false); setLoadState(false); });
    } else {
      setIsLoading(false);
    }
  }, [startDate, endDate, unitName]);
  if (unitName === '') {
    return (
      <Typography variant="h3" color="primary">
        {t(translationKeys.typography.noUnitID)}
      </Typography>
    );
  }
  if (isLoading) {
    return (<BigCircularProgress />);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary">
          {`${t(translationKeys.typography.unitName)} ${unitName.toUpperCase()}`}
        </Typography>
        <Divider />
      </Grid>
      <SummaryCards summaryData={summaryData} />

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Tabs indicatorColor="secondary" value={tabValue} onChange={handleTabChange}>
          <Tab label={t(translationKeys.typography.tabHealthInformation)} {...a11yProps(0)} />
          <Tab label={t(translationKeys.typography.tabFuelInformation)} {...a11yProps(1)} />
        </Tabs>
        <Divider />
        <TabPanel value={tabValue} index={0}>
          <HealthTab
            unitName={unitName}
            startDate={startDate}
            endDate={endDate}
            healthIndicatorData={healthIndicatorData}
            eventsData={eventsData}
            predictionsData={predictionsData}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FuelTab
            fuelSummaryData={fuelSummaryData}
            fuelEvents={fuelEvents}
            fuelUnitData={fuelUnitData}
            startDate={startDate}
            endDate={endDate}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default UnitView;
