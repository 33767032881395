import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider, Grid, Typography,
} from '@material-ui/core';
import translationKeys from '../../i18n/translationKeys';
import { DashboardContext } from './context';
import { getStateUnits, getUserUnitsView } from '../../services/Data';
import UnitDetailsCard, { SkeletonUnitDetailsCard } from '../../components/Dashboard/UnitDetailsCard';

const useStyles = makeStyles((theme) => ({

  divider: {
    margin: theme.spacing(2),
  },
  placeholdCard: {
    height: 300,
  },
}));

const SearchUnit = (props) => {
  const classes = useStyles(props);
  const {
    units, selectedUnit, setSelectedUnit,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h4" color="primary">
                {t(translationKeys.typography.searchUnits)}
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                id="combo-box-units"
                options={units}
                getOptionLabel={(option) => option.unit_name}
                style={{ width: 300 }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} label="Unit ID" variant="outlined" />}
                onChange={(event, unit) => {
                  setSelectedUnit(unit);
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item>
          {selectedUnit ? (
            <UnitDetailsCard unit={selectedUnit} buttonStyle="primary" />
          ) : (<Grid container alignItems="center" className={classes.placeholdCard}><Grid item><Typography>No unit selected</Typography></Grid></Grid>)}
        </Grid>
      </Grid>

    </>
  );
};

const RecentlyUnitsViewed = (props) => {
  const { recentlyViewedUnits, history } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        {t(translationKeys.typography.recentViewedUnits)}
      </Typography>

      <Grid
        container
        direction="row"
        // justify="space-evenly"
        alignItems="center"
        spacing={3}
      >
        {((recentlyViewedUnits !== undefined) && (recentlyViewedUnits.length > 0)) ? (
          recentlyViewedUnits.map((unitObj) => (
            <Grid item key={`${unitObj.last_timestamp}_${unitObj.unit_name}`}>
              <UnitDetailsCard unit={unitObj} history={history} />
            </Grid>
          ))) : (<></>)}
        {((recentlyViewedUnits !== undefined) && (recentlyViewedUnits.length === 0)) ? (
          <Grid item>
            <Typography>
              {t(translationKeys.typography.noRecentViewedUnits)}
            </Typography>
          </Grid>
        ) : (<></>)}
        {(recentlyViewedUnits === undefined) ? (
          new Array(10).fill(undefined).map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={`${index}_placeholder`}>
              <SkeletonUnitDetailsCard />
            </Grid>
          ))) : (<></>)}
      </Grid>
    </>
  );
};

const UnitSearch = (props) => {
  const classes = useStyles(props);
  const { history } = props;
  const [units, setUnits] = useState([]);
  const [recentlyViewedUnits, setRecentlyViewedUnits] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const { setLoadState, locationState } = useContext(DashboardContext);

  useEffect(() => {
    setLoadState(true);
    getStateUnits(locationState).then((results) => {
      setUnits(results);
      setLoadState(false);
    });
  }, [locationState]);

  useEffect(() => {
    getUserUnitsView(10).then((results) => {
      setRecentlyViewedUnits(results.data);
    });
  }, []);

  return (

    <Grid
      container
      direction="column"
      justify="flex-start"
      spacing={3}
    >
      <Divider className={classes.divider} />
      <Grid item xs={12}>
        <SearchUnit units={units} selectedUnit={selectedUnit} setSelectedUnit={setSelectedUnit} history={history} />
      </Grid>
      <Divider className={classes.divider} />
      <Grid item xs={12}>
        <RecentlyUnitsViewed history={history} recentlyViewedUnits={recentlyViewedUnits} />
      </Grid>
    </Grid>

  );
};
export default UnitSearch;
