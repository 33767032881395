import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../i18n/translationKeys';
import Footer from '../../components/Footer';
import { ROOT } from '../../navigation/CONSTANTS';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));

const NotFound = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom>
          {t(translationKeys.typography.pageNotFound)}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {t(translationKeys.typography.pageNotFoundMessage)}
        </Typography>
        <Link to={ROOT}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
          >
            {t(translationKeys.button.pageNotFoundGoHome)}
          </Button>
        </Link>
      </Container>
      <Footer />
    </div>
  );
};

export default NotFound;
