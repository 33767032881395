import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { formatNumber } from '../../utils/index';

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const GroupValueBoxes = (props) => {
  const { title, series } = props;
  const classes = useStyles(props);
  return (
    <>
      <Grid item xs>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        {
          series.map((item) => (
            <Grid key={item.name} item xs md>
              {/* TODO: Replace with ValueBox */}
              <Paper className={classes.paper}>
                <Typography component="h6" variant="h6" gutterBottom>
                  {item.name}
                </Typography>
                <Typography component="p" variant="h5">
                  {formatNumber((item.data.reduce((a, b) => a + b, 0)))}
                </Typography>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};

export default GroupValueBoxes;
