import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import {
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import translationKeys from '../../i18n/translationKeys';
import Footer from '../../components/Footer';
import { getMenuItems } from '../../config/User';
import { DASHBOARD } from '../../navigation/CONSTANTS';
import { drawerWidth } from '../../styles/CONSTANTS';
import { DashboardContext } from './context';

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  drawerFooter: {
    width: `calc(${drawerWidth}-${theme.spacing(1)})`,
    marginTop: 'auto',
  },
  drawerTitle: {
    color: 'primary',
    flexGrow: 1,
    textDecoration: 'none',
  },
  listContainer: {
    padding: 0,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  // warningChip: {
  //   background: theme.palette.warning.dark,
  //   color: theme.palette.secondary.contrastText,
  // },
}));

const PageDrawer = (props) => {
  const classes = useStyles(props);
  const {
    location, history,
  } = props;

  const { t } = useTranslation();
  const { drawerOpen, handleDrawerClose } = useContext(DashboardContext);
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    setItemsList(getMenuItems());
  }, []);

  return (
    <MUIDrawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
      }}
      open={drawerOpen}
    >
      <div className={classes.drawerHeader}>
        <Link to={DASHBOARD} className={classes.drawerTitle}>
          <Typography variant="h4" color="primary" noWrap>
            {t(translationKeys.typography.cerberus)}
          </Typography>

        </Link>
        {/* {process.env.REACT_APP_STAGE
          && (
            <>
              <Tooltip placement="right" title={t(translationKeys.appStageInfo.appVersionMessage)}>
                <Chip className={classes.warningChip} label={process.env.REACT_APP_STAGE} variant="outlined" size="small" />
              </Tooltip>
            </>
          )} */}
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List className={classes.listContainer}>
        {itemsList.map((item) => {
          const { textKey, icon, path } = item;
          return (
            <ListItem
              button
              key={textKey}
              onClick={() => {
                history.push(path);
              }}
              selected={path === location.pathname}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={t(textKey)} />
            </ListItem>
          );
        })}
      </List>
      {drawerOpen ? (<div className={classes.drawerFooter}><Footer /></div>) : (<></>)}
    </MUIDrawer>
  );
};

export default withRouter(PageDrawer);
