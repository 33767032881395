import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const UnitComputedEventsChart = (props) => {
  const {
    eventsData, startDate, endDate,
  } = props;
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    let flattenedEvents = [];
    let eventArray = [];
    flattenedEvents = Object.keys(eventsData).reduce((r, k) => {
      r.push(...eventsData[k]);
      return r;
    }, []);
    eventArray = flattenedEvents.reduce((list, event) => {
      list.push({
        x: event.comment,
        y: [
          new Date(event.timestamp).getTime() - 86300000,
          new Date(event.end_timestamp).getTime()],
      });
      return list;
    }, []);
    setSeriesData([{ data: eventArray }]);
  }, [eventsData]);

  const chartOptions = {
    chart: {
      height: 350,
      type: 'rangeBar',
      group: 'social',
      id: 'fb2',
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
      formatter(val) {
        const a = val[0];
        const b = val[1];
        const diff = b - a;
        return diff + (diff > 1 ? ' days' : ' day');
      },
    },
    fill: {
      type: 'solid',
      opacity: 0.6,
    },
    xaxis: {
      type: 'datetime',
      min: new Date(startDate).getTime(),
      max: new Date(endDate).getTime(),
    },
    yaxis: {
      title: { text: 'Sensor Events' },
      labels: {
        show: false,
        minWidth: 60,
      },
    },
    legend: {
      position: 'top',
    },
    theme: {
      mode: 'dark',
    },
  };

  return (
    <Chart options={chartOptions} series={seriesData} type="rangeBar" height={350} />
  );
};

export default UnitComputedEventsChart;
