import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fab, Button, Chip, Dialog, DialogActions,
  DialogTitle, DialogContent, DialogContentText,
  List, ListItem, TextField, Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import translationKeys from '../../i18n/translationKeys';
import { formatDate } from '../../utils';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  chipList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  appBarItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

}));

const FilterDialog = (props) => {
  const {
    open, setOpen, locationState, startDate, endDate, setLocationState, stateList, setStartDate, setEndDate, loadState,
  } = props;
  const { t } = useTranslation();
  const [canUpdate, setCanUpdate] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const [tempLocationState, setTempLocationState] = useState(locationState);

  useEffect(() => {
    if (startDate === tempStartDate && endDate === tempEndDate && tempLocationState === locationState) {
      setCanUpdate(false);
    } else {
      setCanUpdate(true);
    }
  }, [endDate, startDate, tempStartDate, tempEndDate, locationState, tempLocationState]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="filters-dialog"
    >
      <DialogTitle id="filters-dialog">Filters</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update Filters
        </DialogContentText>
        <form noValidate autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <List>
              <ListItem>
                <Autocomplete
                  id="combo-box-states"
                  options={stateList}
                  getOptionLabel={(option) => option.state}
                  style={{ width: 230 }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} label="State" />}
                  onChange={(event, state) => {
                    setTempLocationState(state?.state);
                  }}
                />
              </ListItem>
              <ListItem>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="start-date-picker"
                  label="Start Date"
                  value={tempStartDate}
                  onChange={(date) => setTempStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                />
              </ListItem>
              <ListItem>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="end-date-picker"
                  label="End Date"
                  value={tempEndDate}
                  onChange={(date) => setTempEndDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change end date',
                  }}
                />
              </ListItem>
            </List>
          </MuiPickersUtilsProvider>
        </form>
      </DialogContent>
      <DialogActions>

        {canUpdate ? (
          <Button
            onClick={() => {
              setTempStartDate(startDate);
              setTempEndDate(endDate);
            }}
            disabled={!canUpdate || loadState}
          >
            {t(translationKeys.button.clearFilters)}
          </Button>
        )
          : (
            <Button
              onClick={handleClose}
            >
              Close
            </Button>
          )}

        <Button
          disabled={!canUpdate || loadState}
          color="primary"
          onClick={() => {
            setStartDate(tempStartDate);
            setEndDate(tempEndDate);
            setLocationState(tempLocationState);
            setOpen(false);
          }}
        >
          {t(translationKeys.button.updateFilters)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FilterBar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    locationState, startDate, endDate, setStartDate, setEndDate, loadState, stateList, setLocationState,
  } = props;
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className={classes.appBarItems}>
        <Typography>{`${t(translationKeys.typography.filters)} :`}</Typography>
        <ul className={classes.chipList}>
          <li><Chip className={classes.chip} label={`State: ${locationState}`} /></li>
          <li><Chip className={classes.chip} label={`Date Range: ${formatDate(startDate)} to ${formatDate(endDate)}`} /></li>
        </ul>
        <Fab disabled={loadState} size="small" color="secondary" onClick={handleClickOpen}>
          <SearchIcon />
        </Fab>
        <FilterDialog
          open={open}
          setOpen={setOpen}
          locationState={locationState}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          loadState={loadState}
          stateList={stateList}
          setLocationState={setLocationState}
        />
      </div>
    </>
  );
};

export default FilterBar;
