import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const FaqItem = (props) => {
  const { title, body } = props;
  return (
    <Grid item>
      <Grid container direction="column" spacing={1} justify="center">
        <Grid item>
          <Typography component="h1" variant="h5" color="primary">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            {body}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FaqView = () => (
  <>
    <Grid container direction="column" spacing={2}>
      <FaqItem
        title="What is a Deep Digital Twin?"
        body="A digital twin is a digital representation of an asset. The digital twin mimics the behaviour of an asset
          by computationally simulating the physics of the asset, i.e. it represents the physical relationship between the
          inputs and outputs of the assets. For instance, we can change the load of the digital twin of a diesel generator
          and see how its fuel rate and RPM will change without physically changing the load of an actual generator. The Deep
          part comes in because we use what is called Deep Learning to train a model to represent the physical asset.
          This is done through using actual sensor readings of an asset to learn the underlying physics of the asset. So we'll
          use thousands of hours of a fleet of diesel generators to teach the Deep Digital Twin how a diesel generator should behave.
          "
      />

      <FaqItem
        title="What is the Health Indicator and how is it calculated?"
        body="The Health Indicator is a metric of the overall health of the diesel generator. In the past, a set of
          hard coded rules based on thresholds would be used to trigger alarms, e.g. the radiator temp is above 95°C.
          We instead use the Deep Digital Twin to analyse the signals of the diesel generator to find patterns
          which indicate healthy operation. When a new set of sensor signals is analysed that differs significantly
          from what the Deep Digital Twin would produce, it is flagged as being unhealthy and
          decreases the Health Indicator score."
      />
      <FaqItem
        title="What is considered healthy operation when creating the Deep Digital Twin?"
        body="In the initial phases of creating the Deep Digital Twin, the model is trained on sensor signals which
          belong to generators that are known to be in a healthy condition. This is done by avoiding signals which
           are close to major maintenance events or breakdowns. We also employ simple rules to filter out signals
           that are obviously wrong, for instance a temperature sensor that is always zero or
           the generator is producing more power than it is consuming (breaking the second law of thermodynamics).
           These rules are not what the Deep Digital Twin learns when it makes future predictions but rather uses
            these rules as a starting point as it learns how a diesel generator should behave."
      />
    </Grid>
  </>
);

export default FaqView;
