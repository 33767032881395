import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const ValueBox = (props) => {
  const { title, value } = props;
  const classes = useStyles(props);
  return (
    <>
      <Typography component="h6" variant="h6" color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Paper className={classes.paper}>
        <Typography component="h4" variant="h4">
          {value}
        </Typography>
      </Paper>
    </>
  );
};

export default ValueBox;
