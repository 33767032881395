import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const SummaryHistogramChart = (props) => {
  const {
    summaryHistogramBins, summaryHistogramData, setModalOpen, setSelectedPercentile,
  } = props;
  let bins = summaryHistogramBins;
  // if (summaryHistogramBins === null) {
  bins = [
    '95 - 100%',
    '90 - 95%',
    '85 - 90%',
    '80 - 85%',
    '75 - 80%',
    '70 - 75%',
    '65 - 70%',
    '60 - 65%',
    '55 - 60%',
    '50 - 55%',
    '45 - 50%',
    '40 - 45%',
    '35 - 40%',
    '30 - 35%',
    '25 - 30%',
    '20 - 25%',
    '15 - 20%',
    '10 - 15%',
    '5 - 10%',
    '0 - 5%',
  ];
  // }
  const [seriesData, setSeriesData] = useState([]);
  const handleClickOpen = (dataPointIndex) => {
    setModalOpen(true);
    setSelectedPercentile(dataPointIndex);
  };

  useEffect(() => {
    const seriesObject = [{ data: summaryHistogramData.bin_counts }];
    setSeriesData(seriesObject);
  }, [summaryHistogramData]);
  const chartOptions = {
    chart: {
      type: 'bar',
      events: {
        dataPointSelection(event, chartContext, config) {
          handleClickOpen(config.dataPointIndex);
        },
      },
    },
    colors: ['#1264A3'],
    plotOptions: {
      bar: {
        barHeight: '97%',
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      offsetX: 0,
      style: {
        colors: ['#fff'],
      },
      formatter(val, opt) {
        return `${opt.w.globals.labels[opt.dataPointIndex]}: ${val}`;
      },
      // dropShadow: {
      //   enabled: true,
      // },
    },

    xaxis: {
      categories: bins,
      title: {
        text: 'Count',
      },
    },
    grid: {
      show: false,
    },
    yaxis: {
      labels: {
        show: false,
      },
      reversed: false,
      axisTicks: {
        show: false,
      },
    },
    theme: {
      mode: 'dark',
    },
    tooltip: {
      theme: 'light',
      x: {
        show: true,
      },
      y: {
        title: {
          formatter() {
            return '';
          },
        },
      },
    },
  };
  return (
    <div>
      <Chart options={chartOptions} series={seriesData} type="bar" height={579} />
    </div>
  );
};

export default SummaryHistogramChart;
