import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import firebase from '../config/Firebase';
import { ROOT as ROOT_PATH, DASHBOARD as DASHBOARD_PATH } from './CONSTANTS';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest} // eslint-disable-line
    render={(props) => (
      firebase.getCurrentUserId()
        ? <Component {...props} /> // eslint-disable-line
        : <Redirect to={ROOT_PATH} />
    )}
  />
);

export const RankedPrivateRoute = ({ component: Component, rank, ...rest }) => (
  <Route
    {...rest} // eslint-disable-line
    render={(props) => (
      firebase.getRank() >= rank
        ? <Component {...props} /> // eslint-disable-line
        : <Redirect to={DASHBOARD_PATH} />
    )}
  />
);

export default PrivateRoute;
