/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const getXAxisAnnotations = (data, showPeriodicAnnotations, showComputedAnnotations) => {
  if (data !== undefined) {
    let periodicAnnotationsList = [];
    if ((showPeriodicAnnotations) && (data.Periodic !== undefined)) {
      periodicAnnotationsList = data.Periodic.reduce((list, event) => {
        list.push({
          x: new Date(event.timestamp).getTime(),
          x2: new Date(event.timestamp).getTime(),
          strokeDashArray: 0,
          borderColor: '#775DD0',
          label: {
            borderColor: '#775DD0',
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: event.type,
          },
        });
        return list;
      }, []);
    }
    let computedAnnotationsList = [];
    let flattenedDataCorrective = [];
    if ((showComputedAnnotations) && (data.Computed !== undefined)) {
      flattenedDataCorrective = Object.keys(data.Computed).reduce((r, k) => {
        r.push(...data.Computed[k]);
        return r;
      }, []);
      computedAnnotationsList = flattenedDataCorrective.reduce((list, event) => {
        list.push({
          x: new Date(event.timestamp).getTime(),
          x2: new Date(event.end_timestamp).getTime(),
          fillColor: '#ffdb00',
          opacity: 0.2,
          label: {
            borderColor: '#ffdb00',
            style: {
              fontSize: '10px',
              color: '#000',
              background: '#ffdb00',
            },
            offsetY: -0,
            text: event.comment,
          },
        });
        return list;
      }, []);
    }
    return periodicAnnotationsList.concat(computedAnnotationsList);
  }
  return [];
};

const getXAxisPredictionAnnotations = (data, showPredictedAnnotations) => {
  if (data !== undefined) {
    let predictedAnnotationsList = [];
    let flattenedData = [];
    if ((showPredictedAnnotations) && (data.prediction !== undefined)) {
      flattenedData = Object.keys(data.prediction).reduce((r, k) => {
        r.push(...data.prediction[k]);
        return r;
      }, []);
      predictedAnnotationsList = flattenedData.reduce((list, event) => {
        list.push({
          x: new Date(event.timestamp).getTime(),
          x2: new Date(event.end_timestamp).getTime(),
          fillColor: '#ff0000',
          opacity: 0.2,
          label: {
            borderColor: '#ff0000',
            style: {
              fontSize: '10px',
              color: '#000',
              background: '#ff0000',
            },
            offsetY: -0,
            text: event.prediction,
          },
        });
        return list;
      }, []);
    }
    return predictedAnnotationsList;
  }
  return [];
};

const getPointAnnotations = (data, showCorrectiveAnnotations, showReplacementAnnotations) => {
  if (data !== undefined || data.length > 0) {
    let correctiveAnnotationsList = [];
    if ((showCorrectiveAnnotations) && (data.Corrective !== undefined)) {
      correctiveAnnotationsList = data.Corrective.reduce((list, event) => {
        list.push({
          x: new Date(event.timestamp).getTime(),
          y: event.health_indicator,
          marker: {
            size: 8,
            fillColor: '#fff',
            strokeColor: 'orange',
            radius: 2,
          },
          label: {
            borderColor: '#fC7F38',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#fC7F38',
            },

            text: event.type,
          },
        });
        return list;
      }, []);
    }

    let replacementAnnotationsList = [];
    if ((showReplacementAnnotations) && (data.Replacement !== undefined)) {
      replacementAnnotationsList = data.Replacement.reduce((list, event) => {
        list.push({
          x: new Date(event.timestamp).getTime(),
          y: event.health_indicator,
          marker: {
            size: 8,
            fillColor: '#fff',
            strokeColor: 'red',
            radius: 2,
          },
          label: {
            borderColor: '#FF4560',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#FF4560',
            },

            text: event.type,
          },
        });
        return list;
      }, []);
    }
    return replacementAnnotationsList.concat(correctiveAnnotationsList);
  }
  return [];
};

const UnitHealthIndicatorChart = (props) => {
  const {
    unitName, healthIndicatorData, eventsData, predictionsData, startDate, endDate,
  } = props;
  const [showComputedAnnotations, setShowComputedAnnotations] = useState(false);
  const [showPeriodicAnnotations, setShowPeriodicAnnotations] = useState(true);
  const [showCorrectiveAnnotations, setShowCorrectiveAnnotations] = useState(true);
  const [showReplacementAnnotations, setShowReplacementAnnotations] = useState(true);
  const [showPredictedAnnotations, setShowPredictedAnnotations] = useState(true);
  const [seriesData, setSeriesData] = useState([]);
  const [tooltipEventsData, setEventsData] = useState([]);

  useEffect(() => {
    const processedData = {
      name: 'Health Indicator',
      data: healthIndicatorData.reduce((dataArray, val) => {
        dataArray.push([val.date, 100 * val.health_indicator_value]);
        return dataArray;
      }, []),
    };
    setSeriesData([processedData]);
  }, [healthIndicatorData]);

  const chartOptions = {
    chart: {
      type: 'area',
      stacked: false,
      group: 'social2',
      id: 'fb1',
      height: 350,
      zoom: {
        enabled: false,
      },

    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 1,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        formatter(val) {
          return (val).toFixed(2);
        },
        minWidth: 60,
      },
      title: {
        text: 'Health Indicator (%)',
      },
    },
    xaxis: {
      type: 'datetime',
      min: new Date(startDate).getTime(),
      max: new Date(endDate).getTime(),
    },
    tooltip: {
      shared: false,
      y: {
        formatter(val) {
          return (val).toFixed(6);
        },
      },
    },
    annotations: {
      xaxis:
        getXAxisAnnotations(eventsData, showPeriodicAnnotations, showComputedAnnotations),
      points: getPointAnnotations(eventsData, showCorrectiveAnnotations, showReplacementAnnotations),
    },
    theme: {
      mode: 'dark',
    },

  };

  return (
    <Chart options={chartOptions} series={seriesData} type="area" height={350} />
  );
};

export default UnitHealthIndicatorChart;
