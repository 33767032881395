import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import translationKeys from '../../i18n/translationKeys';
import Footer from '../../components/Footer';
import { DASHBOARD as DASHBOARD_PATH } from '../../navigation/CONSTANTS';
import firebase from '../../config/Firebase';
import KriterionLogo from '../../assets/logo.svg';
import MicrosoftIcon from '../../assets/ms-symbollockup_mssymbol_19.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95vh',
  },
  grid: {
    minHeight: '87vh',
    background: 'linear-gradient(to bottom, #FFFFFF 30%, #303030)',
  },
  innerGrid: {
    minHeight: '87vh',
  },
  paper: { padding: theme.spacing(2) },

  avatar: {
    margin: theme.spacing(1),
  },

  form: {
    // width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0, 0),
  },
}));

const Login = (props) => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loadState, setLoadState] = useState(false);

  if (firebase.getCurrentUserId()) {
    // eslint-disable-next-line no-console
    console.log('Logged in.');
    return (<Redirect to={DASHBOARD_PATH} />);
  }

  async function login() {
    try {
      setLoadState(true);
      await firebase.loginWithMicrosoft();
      props.history.replace(DASHBOARD_PATH);
    } catch (error) {
      enqueueSnackbar(t(translationKeys.snackbar.loginFailed), { variant: 'error' });
      setLoadState(false);
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.grid}
      >
        <Grid item xs={12}>
          <img src={KriterionLogo} alt="Kriterion Logo" />
        </Grid>
        <Grid item xs={12}>
          <br />
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t(translationKeys.typography.loginText)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            disabled={loadState}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<img src={MicrosoftIcon} alt="" />}
            className={classes.submit}
            onClick={() => login()}
          >
            {t(translationKeys.button.loginWindows)}
          </Button>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Login;
