import { setup } from 'axios-cache-adapter';
import firebase from '../Firebase';

const API_BASE = window.REACT_APP_API || '';

const instance = setup({
  baseURL: API_BASE,
  cache: {
    // 1 hour
    maxAge: 60 * 60 * 1000,
    exclude: { query: false },
  },
});

instance.interceptors.request.use(
  async (config) => {
    if (firebase.getCurrentUserId()) {
      const token = await firebase.getIdToken();
      config.headers.common.Authorization = `Bearer ${token}`;
      config.headers.common.Pragma = 'no-cache';
    }
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  (error) => Promise.reject(error),
);

instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.get['Content-Type'] = 'application/json';

export default instance;
