import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Tooltip, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import UnitHealthIndicatorChart from '../../../components/Dashboard/UnitHealthIndicatorChart';
import UnitEvents from '../../../components/Dashboard/UnitEvents';
import UnitComputedEventsChart from '../../../components/Dashboard/UnitComputedEventsChart';
import FeedbackPopUp from '../../../components/FeedbackPopUp';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const HealthTab = (props) => {
  const classes = useStyles(props);
  const {
    unitName, startDate, endDate,
    healthIndicatorData, eventsData, predictionsData,
  } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={12}>
        <FeedbackPopUp
          header="Health Indicator Graph"
          elementId="hi_graph"
          page="unit_view"
          child={(
            <Tooltip
              title="Time history of the unit's health indicator, with recorded maintenance events overlaid."
              placement="right"
            >
              <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Health Indicator
              </Typography>
            </Tooltip>
)}
        />

        <Paper className={classes.paper}>
          <UnitHealthIndicatorChart
            unitName={unitName}
            healthIndicatorData={healthIndicatorData}
            eventsData={eventsData}
            predictionsData={predictionsData}
            startDate={startDate}
            endDate={endDate}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <FeedbackPopUp
          header="Sensor Events"
          elementId="sensor_events"
          page="unit_view"
          child={(
            <Tooltip title="History of sensor events. These are hard coded rules which indicate when something is obviously wrong with the sensor readings." placement="right">
              <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Sensor Events
              </Typography>
            </Tooltip>
)}
        />

        <Paper className={classes.paper}>
          {(eventsData.Computed !== undefined && healthIndicatorData.health_indicator_value !== []) ? (
            <>
              <UnitComputedEventsChart
                eventsData={eventsData.Computed}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          ) : (<></>)}
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <FeedbackPopUp
          header="Events List"
          elementId="events_list"
          page="unit_view"
          child={(
            <Tooltip title="Recorded events as taken from the SAV dataset" placement="right">
              <Typography component="h1" variant="h5" color="primary" gutterBottom>
                Events
              </Typography>
            </Tooltip>
)}

        />

        <Grid container spacing={3}>
          {(eventsData !== undefined) ? (
            <UnitEvents eventsData={eventsData} />) : (<></>)}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HealthTab;
