import React, { useEffect, useState } from 'react';
import 'typeface-roboto';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import RouterConfig from './navigation/RouterConfig';
import theme from './styles/theme';
import firebase from './config/Firebase';
import BigCircularProgress from './components/BigCircularProgress';

const App = () => {
  const [firebaseInitialised, setFirebaseInitialised] = useState(false);
  useEffect(() => {
    firebase.isInitialised().then((val) => {
      setFirebaseInitialised(val);
    });
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider hideIconVariant={false}>
          {firebaseInitialised !== false ? (
            <Router>
              <RouterConfig />
            </Router>
          ) : (
            <BigCircularProgress />
          )}
        </SnackbarProvider>
      </MuiThemeProvider>
    </I18nextProvider>
  );
};

export default App;
