export default {
  typography: {
    welcome: 'typography:welcome',
    companyName: 'typography:companyName',
    loginText: 'typography:loginText',
    poweredBy: 'typography:poweredBy',
    kriterion: 'typography:kriterion',
    cerberus: 'typography:cerberus',
    pageNotFound: 'typography:pageNotFound',
    pageNotFoundMessage: 'typography:pageNotFoundMessage',
    dashboard: 'typography:dashboard',
    filters: 'typography:filters',
    loadingText: 'typography:loadingText',
    recentViewedUnits: 'typography:recentViewedUnits',
    noRecentViewedUnits: 'typography:noRecentViewedUnits',
    searchUnits: 'typography:searchUnits',
    unitName: 'typography:unitName',
    unitState: 'typography:unitState',
    unitRegion: 'typography:unitRegion',
    unitFuelVendor: 'typography:unitFuelVendor',
    tabHealthInformation: 'typography:tabHealthInformation',
    tabFuelInformation: 'typography:tabFuelInformation',
    noUnitID: 'typography:noUnitID',
    totalFuelConsumption: 'typography:totalFuelConsumption',
    averageFuelConsumption: 'typography:averageFuelConsumption',
    totalFuelDrop: 'typography:totalFuelDrop',
    totalFuelFill: 'typography:totalFuelFill',
    refuel: 'typography:refuel',
    fuelDrop: 'typography:fuelDrop',
    refuelComparison: 'typography:refuelComparison',
    fuelDropComparison: 'typography:fuelDropComparison',
    total: 'typography:total',
    reconciled: 'typography:reconciled',
    unreconciled: 'typography:unreconciled',
    fuelComparison: 'typography:fuelComparison',
    insufficientData: 'typography:insufficientData',
  },
  button: {
    login: 'button:login',
    loginWindows: 'button:loginWindows',
    backToLogin: 'button:backToLogin',
    pageNotFoundGoHome: 'button:pageNotFoundGoHome',
    updateFilters: 'button:updateFilters',
    clearFilters: 'button:clearFilters',
    viewUnit: 'button:viewUnit',
    submit: 'button:submit',
    cancel: 'button:cancel',
  },
  label: {
    email: 'label:email',
    password: 'label:password',
    startDate: 'label:startDate',
    endDate: 'label:endDate',
    loading: 'label:loading',
    filterState: 'label:filterState',
  },
  menu: {
    logout: 'menu:logout',
    profile: 'menu:profile',
    dashboard: 'menu:dashboard',
    overview: 'menu:overview',
    fuelRecon: 'menu:fuelRecon',
    unitSearch: 'menu:unitSearch',
    unitView: 'menu:unitView',
    faq: 'menu:faq',
  },
  snackbar: {
    loginFailed: 'snackbar:loginFailed',
    apiErrorUnitHealthIndicator: 'snackbar:apiErrorUnitHealthIndicator',
    apiErrorUnitEvent: 'snackbar:apiErrorUnitEvent',
    apiErrorUnitPredictionsEvent: 'snackbar:apiErrorUnitPredictionsEvent',
    apiErrorUnitSummary: 'snackbar:apiErrorUnitSummary',
    apiErrorUnitFuelSummary: 'snackbar:apiErrorUnitFuelSummary',
    apiErrorUnitFuelEvent: 'snackbar:apiErrorUnitFuelEvent',
    apiErrorStateFuelSummary: 'snackbar:apiErrorStateFuelSummary',
    apiErrorStateFuelRecon: 'snackbar:apiErrorStateFuelRecon',
    apiErrorStateFuelDropEvent: 'snackbar:apiErrorStateFuelDropEvent',
    apiErrorStateFuelFillEvent: 'snackbar:apiErrorStateFuelFillEvent',
    feedbackLoggingError: 'snackbar:feedbackLoggingError',
    feedbackLoggingSuccess: 'snackbar:feedbackLoggingSuccess',
  },
  appStageInfo: {
    disabledTrial: 'appStageInfo:disabledTrial',
    appVersionMessage: 'appStageInfo:appVersionMessage',
    feedbackMessage: 'appStageInfo:feedbackMessage',
  },
  dialog: {
    feedback: 'dialog:feedback',
    comment: 'dialog:comment',
    prompt: 'dialog:prompt',
    useful: 'dialog:Useful',
    unclear: 'dialog:unclear',
    other: 'dialog:other',
    buggy: 'dialog:buggy',
    notRelevant: 'dialog:notRelevant',
  },
};
