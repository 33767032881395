import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { formatNumber } from '../../../utils/index';
import FeedbackPopUp from '../../../components/FeedbackPopUp';

const UnitFuelPlot = (props) => {
  const {
    title, series, startDate, endDate,
  } = props;
  const [seriesData, setSeriesData] = useState([]);
  const [consumptionSeriesData, setConsumptionSeriesData] = useState([]);
  useEffect(() => {
    setSeriesData(series.candlestick.data);
    setConsumptionSeriesData(series.bar.data);
  }, [series]);
  const candleChartOptions = {
    chart: {
      id: 'candlestick-1',
      type: 'candlestick',
      group: 'fuel',
      height: 290,

      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'datetime',
      min: startDate.getTime(),
      max: endDate.getTime(),
    },
    yaxis: {
      title: {
        text: 'Fuel Level (L)',
      },
      labels: {
        formatter(val) {
          return formatNumber(val, 'L');
        },
        minWidth: 40,
      },
    },
    theme: {
      mode: 'dark',
    },

  };
  const barChartOptions = {
    chart: {
      id: 'bar-1',
      type: 'bar',
      // group: 'fuel',
      height: 160,

      toolbar: {
        autoSelected: 'pan',
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      min: startDate.getTime(),
      max: endDate.getTime(),
    },
    yaxis: {
      title: {
        text: 'Fuel Consumption (L)',
      },
      labels: {
        formatter(val) {
          return formatNumber(val, 'L');
        },
        minWidth: 40,
      },
    },
    theme: {
      mode: 'dark',
    },
  };
  return (
    <>

      <FeedbackPopUp
        header="Fuel Consumption Chart"
        elementId="fuel_consumption_chart"
        page="unit_view"
        child={(
          <Tooltip title="Fuel level as well as consumption are shown. The consumption values are calculated after the fuel level has been smoothed and filtered." placement="right">
            <Typography component="h2" variant="h6" color="primary" gutterBottom>{title}</Typography>
          </Tooltip>
)}
      />

      <ReactApexChart
        options={candleChartOptions}
        series={[{
          name: 'Fuel Level',
          data: seriesData,
        }]}
        type="candlestick"
        height={350}
      />

      <ReactApexChart
        options={barChartOptions}
        series={[{
          name: 'Fuel Consumption',
          data: consumptionSeriesData,
        }]}
        type="bar"
        height={150}
      />
    </>
  );
};

export default UnitFuelPlot;
