import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Grid, Paper, Tooltip, Typography,
} from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import {
  getFuelReconData, getStateFuelSummary,
} from '../../services/Data';
import { DashboardContext } from './context';
import GroupValueBoxes from '../../components/Dashboard/GroupValueBoxes';
import ValueBox from '../../components/Dashboard/ValueBox';
import { formatNumber } from '../../utils/index';
import { DASHBOARD_UNIT } from '../../navigation/CONSTANTS';
import translationKeys from '../../i18n/translationKeys';
import LinkButton from '../../components/LinkButton';
import FeedbackPopUp from '../../components/FeedbackPopUp';

const useStyles = makeStyles((theme) => ({
  table: {
    height: '577px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    height: '660px',
  },
}));

const getSeries = (fuelReconData, key, OnlyGAndK = false) => {
  if (OnlyGAndK) {
    return [{
      name: 'Galooli',
      data: fuelReconData.map((val) => val[`galooli_${key}`]).map(parseFloat),
    },
    {
      name: 'Kriterion',
      data: fuelReconData.map((val) => val[`kriterion_${key}`]).map(parseFloat),
    }];
  }
  return [{
    name: 'Galooli',
    data: fuelReconData.map((val) => val[`galooli_${key}`]).map(parseFloat),
  },
  {
    name: 'Kriterion',
    data: fuelReconData.map((val) => val[`kriterion_${key}`]).map(parseFloat),
  },
  {
    name: 'SAV Dipstick',
    data: fuelReconData.map((val) => val[`sav_dipstick_${key}`]).map(parseFloat),
  },
  {
    name: 'SAV Sensor',
    data: fuelReconData.map((val) => val[`sav_sensor_${key}`]).map(parseFloat),
  }];
};
// Fuel summary
const FuelSummaryCards = (props) => {
  const {
    t, totalFuelConsumption, avgDailyFuelConsumption,
    totalRefill, totalRefillReconciled, totalRefillUnreconciled,
    totalDrop, totalDropReconciled, totalDropUnreconciled,
  } = props;

  return (
    <Grid container direction="column">
      {/*  Consumption */}
      <Grid item xs>

        <FeedbackPopUp
          header="Fuel Summary"
          elementId="fuel_summary"
          page="fuel_summary"
          child={(
            <Tooltip
              title="Gives a reconciled overview of the fuel events for the fleet.
                      A reconciled event is taken as any sensor reading that occurs on the same day as a SAV fuel capture sheet event"
              placement="right"
            >
              <Typography variant="h5" color="primary" gutterBottom>
                Fuel Summary
              </Typography>
            </Tooltip>
)}
        />

        <Grid container spacing={3}>
          <Grid item xs>
            <ValueBox
              title={t(translationKeys.typography.totalFuelConsumption)}
              value={formatNumber(totalFuelConsumption || null)}
            />
          </Grid>
          <Grid item xs>
            <ValueBox
              title={t(translationKeys.typography.averageFuelConsumption)}
              value={formatNumber(avgDailyFuelConsumption || null, 'per unit')}
            />
          </Grid>
        </Grid>
      </Grid>
      {/*  Fills */}
      <Grid item xs>

        <Typography variant="h6" color="textSecondary" gutterBottom>
          {t(translationKeys.typography.refuel)}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs>
            <ValueBox title={t(translationKeys.typography.total)} value={formatNumber(totalRefill || null)} />
          </Grid>
          <Grid item xs>
            <ValueBox title={t(translationKeys.typography.reconciled)} value={formatNumber(totalRefillReconciled || null)} />
          </Grid>
          <Grid item xs>
            <ValueBox title={t(translationKeys.typography.unreconciled)} value={formatNumber(totalRefillUnreconciled || null)} />
          </Grid>
        </Grid>
      </Grid>
      {/*  Drops */}
      <Grid item xs>

        <Typography variant="h6" color="textSecondary" gutterBottom>
          {t(translationKeys.typography.fuelDrop)}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs>
            <ValueBox title={t(translationKeys.typography.total)} value={formatNumber(totalDrop || null)} />
          </Grid>
          <Grid item xs>
            <ValueBox title={t(translationKeys.typography.reconciled)} value={formatNumber(totalDropReconciled || null)} />
          </Grid>
          <Grid item xs>
            <ValueBox title={t(translationKeys.typography.unreconciled)} value={formatNumber(totalDropUnreconciled || null)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FuelReconView = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    startDate, endDate, setLoadState, locationState,
  } = useContext(DashboardContext);
  const [fuelReconData, setFuelReconData] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [fuelSeries, setFuelSeries] = useState([]);
  useEffect(() => {
    setLoadState(true);
    const fetchData = async () => {
      await Promise.all([
        getStateFuelSummary(locationState, startDate, endDate)
          .then((result) => { setSummaryData(result); })
          .catch(() => {
            setSummaryData();
            enqueueSnackbar(t(translationKeys.snackbar.apiErrorStateFuelSummary), {
              variant: 'error',
            });
          }),
        getFuelReconData(locationState, startDate, endDate)
          .then((result) => {
            setFuelReconData(result.measurement_comparison_by_state);
            setFuelSeries(result.measurement_comparison_by_unit);
          })
          .catch(() => {
            setFuelReconData([]);
            enqueueSnackbar(t(translationKeys.snackbar.apiErrorStateFuelRecon), {
              variant: 'error',
            });
          }),
      ]);
    };
    fetchData().finally(() => setLoadState(false));
  }, [startDate, endDate, locationState]);

  const FillColumns = [
    {
      field: 'id',
      headerName: t(translationKeys.typography.unitName),
      flex: 1,
      disableClickEventBubbling: false,
      renderCell: ({ value }) => <LinkButton fullWidth variant="contained" color="secondary" to={`${DASHBOARD_UNIT}${value}`}>{value}</LinkButton>,
    },
    {
      field: 'galooli_monthly_fill',
      headerName: 'Galooli',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      field: 'kriterion_monthly_fill',
      headerName: 'Kriterion',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      field: 'sav_sensor_monthly_fill',
      headerName: 'SAV Sensor',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      field: 'sav_dipstick_monthly_fill',
      headerName: 'SAV Dipstick',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      field: 'kriterion_monthly_fill_count',
      headerName: 'Kriterion Fill Count',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      field: 'galooli_monthly_fill_count',
      headerName: 'Galooli Fill Count',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      field: 'kriterion_monthly_drop_count',
      headerName: 'Kriterion Drop Count',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
    {
      field: 'galooli_monthly_drop_count',
      headerName: 'Galooli  Drop Count',
      type: 'number',
      flex: 1,
      valueFormatter: (params) => (formatNumber(params.value)),
    },
  ];

  return (
    <>
      <Grid
        container
        justify="flex-start"
        direction="column"
        // alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs>
          <Grid
            container
            direction="column"
            justify="flex-start"
            spacing={3}
          >
            {/* Summary Stats */}
            <Grid item xs>
              {/* Total and average consumption */}
              <FuelSummaryCards
                t={t}
                totalFuelConsumption={summaryData?.total_fuel_consumption}
                avgDailyFuelConsumption={summaryData?.avg_daily_fuel_consumption}
                totalRefill={summaryData?.total_refill}
                totalRefillReconciled={summaryData?.total_refill_reconciled}
                totalRefillUnreconciled={summaryData?.total_refill_unreconciled}
                totalDrop={summaryData?.total_drop}
                totalDropReconciled={summaryData?.total_drop_reconciled}
                totalDropUnreconciled={summaryData?.total_drop_unreconciled}
              />
            </Grid>
            {/* Different measurements */}
            <Grid item xs>

              <FeedbackPopUp
                header="Measurement Comparison"
                elementId="measurement comparison"
                page="fuel_summary"
                child={(
                  <Tooltip
                    title={(
                      <>
                        Comparison of different methods of calculating fuel events.
                        <ul>
                          <li>
                            Galooli: The fuel amount associated with the event before the signal has been smoothed and filtered.
                          </li>
                          <li>
                            Kriterion: The fuel amount associated with the event after the signal has been smoothed and filtered.
                          </li>
                          <li>
                            SAV sensor: The fuel amount captured from the sensor readings by the refuelling technician.
                          </li>
                          <li>
                            SAV dipstick: The fuel amount captured by using a physical dipstick by the refuelling technician.
                          </li>
                        </ul>

                      </>
            )}
                    placement="right"
                  >
                    <Typography variant="h5" color="primary" gutterBottom>
                      {t(translationKeys.typography.fuelComparison)}
                    </Typography>
                  </Tooltip>
)}
              />

              <Grid container direction="column" spacing={3}>
                <Grid item xs>
                  <GroupValueBoxes title={t(translationKeys.typography.refuelComparison)} series={getSeries(fuelReconData, 'monthly_fill')} />
                </Grid>
                <Grid item xs>
                  <GroupValueBoxes title={t(translationKeys.typography.fuelDropComparison)} series={getSeries(fuelReconData, 'monthly_drop')} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>

          <FeedbackPopUp
            header="Fuel Chart"
            elementId="fuel_chart"
            page="fuel_summary"
            child={(
              <Tooltip title="Fuel measurement comparison for each unit over the given time period. Count of events is also shown." placement="right">
                <Typography variant="h5" color="primary" gutterBottom>
                  Fuel Chart
                </Typography>
              </Tooltip>
)}
          />
          {/* <Typography component="h6" variant="h6" color="primary" gutterBottom> */}
          {/*  {`Top ${topNCount} Sites by Fuel Consumptionalskdj;laksjd;lajd;ljka;sjda;ls`} */}
          {/* </Typography> */}
          <Paper className={classes.paper}>
            <DataGrid
              columns={FillColumns}
              rows={fuelSeries}
              pageSize={10}
              minWidth={100}
              autoHeight
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default FuelReconView;
