import React from 'react';
import {
  Box,
  Button, Grid, IconButton, Tooltip,
} from '@material-ui/core';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../i18n/translationKeys';
import { logUserWidgetFeedback } from '../../services/Data';

function RadioButtonsGroup(props) {
  const { option } = props;
  const { t } = useTranslation();
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{t(translationKeys.dialog.prompt)}</FormLabel>
      <RadioGroup aria-label="Options" name="options" value={option} onChange={handleChange}>
        <FormControlLabel value="Useful" control={<Radio />} label={t(translationKeys.dialog.useful)} />
        <FormControlLabel value="Unclear" control={<Radio />} label={t(translationKeys.dialog.unclear)} />
        <FormControlLabel value="Not relevant" control={<Radio />} label={t(translationKeys.dialog.notRelevant)} />
        <FormControlLabel value="Buggy" control={<Radio />} label={t(translationKeys.dialog.buggy)} />
        <FormControlLabel value="Other" control={<Radio />} label={t(translationKeys.dialog.other)} />
      </RadioGroup>
    </FormControl>
  );
}

function FeedbackPopUp(props) {
  const {
    header, elementId, page, child,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [option, setOption] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const feedback = {
        response: option,
        comment: description,
        page,
        elementId,
      };
      await logUserWidgetFeedback(`${elementId}_${page}`, feedback);
      setOpen(false);
      enqueueSnackbar(t(translationKeys.snackbar.feedbackLoggingSuccess), {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(t(translationKeys.snackbar.feedbackLoggingError), {
        variant: 'error',
      });
    }
  };

  function handleOptionChange(newValue) {
    setOption(newValue);
    if (newValue !== '') {
      setButtonDisabled(false);
    }
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  return (
    <div>
      <Grid container spacing={1} alignItems="center" directions="row" justify="flex-start">
        <Grid item>
          {child}
        </Grid>
        <Grid item>
          <Tooltip title="Feedback" placement="right">
            <IconButton edge="end" size="small" onClick={handleClickOpen}>
              <FeedbackOutlinedIcon color="disabled" />
            </IconButton>
          </Tooltip>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              {header}
              {' '}
              Feedback
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t(translationKeys.dialog.feedback)}
              </DialogContentText>
              <Box mt={3}>
                <RadioButtonsGroup option={option} onChange={handleOptionChange} />
              </Box>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t(translationKeys.dialog.comment)}
                fullWidth
                value={description}
                onChange={handleDescriptionChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t(translationKeys.button.cancel)}
              </Button>
              <Button onClick={handleSubmit} color="primary" disabled={buttonDisabled}>
                {t(translationKeys.button.submit)}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>

    </div>
  );
}

export default FeedbackPopUp;
