import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { formatNumber } from '../../../utils/index';
import UnitFuelPlot from './UnitFuelPlot';
import UnitFuelDataTable from './UnitFuelDataTable';
import ValueBox from '../../../components/Dashboard/ValueBox';
import translationKeys from '../../../i18n/translationKeys';
import FeedbackPopUp from '../../../components/FeedbackPopUp';

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    height: theme.spacing(40),
  },
}));

const columns = [
  {
    field: 'id', headerName: 'id', width: 50,
  },
  {
    field: 'date', headerName: 'Date', width: 130, type: 'date',
  },
  { field: 'event', headerName: 'Event', width: 130 },
  {
    field: 'detected_amount', headerName: 'Amount', type: 'number', width: 200,
  },
  {
    field: 'sensor_reading', headerName: 'SAV Sensor', type: 'number', width: 200,
  },
  {
    field: 'dipstick_reading', headerName: 'SAV Dipstick', type: 'number', width: 200,
  },
];

const FuelTab = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const {
    fuelSummaryData, fuelEvents, fuelUnitData, startDate, endDate,
  } = props;
  return (
    <Grid container spacing={2}>
      {fuelSummaryData ? (
        <>
          <Grid item xs={12}>
            <FeedbackPopUp
              header="Fuel Summary"
              elementId="fuel_summary"
              page="unit_view"
              child={(
                <Tooltip
                  title="Gives a reconciled overview of the fuel events for the given unit.
                      A reconciled event is taken as any sensor reading that occurs on the same day as a SAV fuel capture sheet event"
                  placement="right"
                >
                  <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Fuel Summary
                  </Typography>
                </Tooltip>
              )}

            />
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <ValueBox title={t(translationKeys.typography.totalFuelConsumption)} value={formatNumber(fuelSummaryData.fuel_consumption)} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={4} md={3}>
                <ValueBox title={t(translationKeys.typography.totalFuelDrop)} value={formatNumber(fuelSummaryData.total_drop)} />
              </Grid>
              <Grid item xs={4} md={3}>
                <ValueBox title={t(translationKeys.typography.reconciled)} value={formatNumber(fuelSummaryData.drop_reconciled)} />
              </Grid>
              <Grid item xs={4} md={3}>
                <ValueBox title={t(translationKeys.typography.unreconciled)} value={formatNumber(fuelSummaryData.drop_unreconciled)} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={4} md={3}>
                <ValueBox title={t(translationKeys.typography.totalFuelFill)} value={formatNumber(fuelSummaryData.total_refill)} />
              </Grid>
              <Grid item xs={4} md={3}>
                <ValueBox title={t(translationKeys.typography.reconciled)} value={formatNumber(fuelSummaryData.refill_reconciled)} />
              </Grid>
              <Grid item xs={4} md={3}>
                <ValueBox title={t(translationKeys.typography.unreconciled)} value={formatNumber(fuelSummaryData.refill_unreconciled)} />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (<></>)}
      <Grid item xs={12}>
        <UnitFuelPlot
          title="Fuel Level"
          series={fuelUnitData}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>

      <Grid item xs={12}>
        <FeedbackPopUp
          header="Fuel Events"
          elementId="fuel_events"
          page="unit_view"
          child={(
            <Tooltip
              title={(
                <>
                  Fuel events as taken from the SAV fuel events capture sheets.
                  <ul>
                    <li>
                      Amount: The fuel amount associated with the event after the signal has been smoothed and filtered.
                    </li>
                    <li>
                      SAV sensor: The fuel amount captured from the sensor readings by the refuelling technician.
                    </li>
                    <li>
                      SAV dipstick: The fuel amount captured by using a physical dipstick by the refuelling technician.
                    </li>
                  </ul>

                </>
            )}
              placement="right"
            >
              <Typography component="h2" variant="h6" color="primary" gutterBottom>Fuel Events</Typography>
            </Tooltip>
          )}
        />
        <div className={classes.dataGrid}>
          <UnitFuelDataTable columns={columns} rows={fuelEvents} />
        </div>
      </Grid>

    </Grid>
  );
};
export default FuelTab;
