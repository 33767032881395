import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import translationKeys from '../../i18n/translationKeys';
import { DASHBOARD_UNIT } from '../../navigation/CONSTANTS';
import LinkButton from '../LinkButton';

const capitalise = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const processString = (sentence) => {
  if (sentence === undefined) {
    return '';
  }
  sentence = sentence.toLowerCase();
  return sentence.split(' ').map(capitalise).join(' ');
};
const formatDate = (strDate) => {
  if (strDate === undefined) {
    return '';
  }
  const date = moment(strDate);
  return date.format('YY-MM-DD HH:mm');
};

const useStyles = makeStyles(() => ({
  cardRoot: {
    textAlign: 'left',
    minWidth: 250,
  },
  cardTitle: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export const SkeletonUnitDetailsCard = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Card className={classes.cardRoot} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
          {t(translationKeys.typography.unitName)}
        </Typography>
        <Typography variant="h5">
          <Skeleton />
        </Typography>

        <Typography className={classes.pos} color="textSecondary">
          <Skeleton />
        </Typography>

        {/* <Typography className={classes.pos} color="textSecondary">
          <Skeleton />
        </Typography>

        {<Typography className={classes.pos} color="textSecondary">
          <Skeleton />
        </Typography> */}

      </CardContent>
      <CardActions>
        <Skeleton variant="rect" width={103} height={36} />
      </CardActions>
    </Card>
  );
};

const ShowUnitProperty = (props) => {
  const classes = useStyles(props);
  const { name, value, isDate } = props;
  if (value) {
    return (
      <Typography className={classes.pos} color="textSecondary">
        {isDate ? (<>{`${name}: ${formatDate(value)}`}</>) : (<>{`${name}: ${processString(value)}`}</>)}
      </Typography>
    );
  }
  return (<></>);
};

const UnitDetailsCard = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { unit, buttonStyle } = props;

  return (
    <Card className={classes.cardRoot} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
          {t(translationKeys.typography.unitName)}
        </Typography>
        <Typography variant="h5">
          {unit?.unit_name.toUpperCase()}
        </Typography>
        <Grid
          spacing={1}
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <ShowUnitProperty name={t(translationKeys.typography.unitState)} value={unit?.state} />
          </Grid>
          <Grid item>
            <ShowUnitProperty name={t(translationKeys.typography.unitRegion)} value={unit?.region} />
          </Grid>
          <Grid item>
            <ShowUnitProperty name={t(translationKeys.typography.unitFuelVendor)} value={unit?.vendor} />
          </Grid>
          <Grid item>
            <ShowUnitProperty name="Last Viewed" isDate value={unit?.last_timestamp} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <LinkButton
          variant="contained"
          color={buttonStyle || 'secondary'}
          to={`${DASHBOARD_UNIT}${unit?.unit_name}`}
        >
          {t(translationKeys.button.viewUnit)}
        </LinkButton>
      </CardActions>
    </Card>

  );
};
export default UnitDetailsCard;
