import React from 'react';
import {
  Button,
  Card, CardContent, Grid, Typography, Tooltip,
} from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../utils/index';
import ValueBox from './ValueBox';
import FeedbackPopUp from '../FeedbackPopUp';
import translationKeys from '../../i18n/translationKeys';

export const HealthCard = (props) => {
  const {
    cardType, efficiency, fuelConsumption, powerOutput,
  } = props;

  // assume normal
  let headerText = 'Above HI threshold';
  if (cardType === 'delta') {
    headerText = 'Performance Gain';
  } else if (cardType === 'abnormal') {
    headerText = 'Below HI threshold';
  }
  return (
    <>
      <Typography component="h6" variant="h6" color="textSecondary" gutterBottom>
        {headerText}
      </Typography>
      <Card>
        <CardContent>
          <Typography>
            {`Efficiency: ${formatNumber(efficiency, '%')}`}
          </Typography>
          <Typography>
            {`Consumption: ${formatNumber(fuelConsumption, 'L')}`}
          </Typography>
          <Typography>
            {`Output: ${formatNumber(powerOutput, 'kWh')}`}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export const NullCard = (props) => {
  const {
    numberOfUnits, setNullModalOpen,
  } = props;

  // assume normal
  return (
    <>
      <Card>
        <CardContent>
          <Typography>
            {`Number of Units: ${numberOfUnits || '--'}`}
          </Typography>
          <CardActions>
            <Button
              disabled={numberOfUnits <= 0 || numberOfUnits === null || numberOfUnits === undefined}
              variant="contained"
              color="secondary"
              onClick={() => {
                (setNullModalOpen(true));
              }}
            >
              View Units
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </>
  );
};

const MetricAverages = (props) => {
  const {
    healthIndicator, generatorOutput, fuelConsumption,
  } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={4}
      >
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <ValueBox title="Health Indicator" value={formatNumber(healthIndicator, '%')} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <ValueBox title="Daily Generator Output" value={formatNumber(generatorOutput, 'kWh')} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <ValueBox title="Daily Fuel Consumption" value={formatNumber(fuelConsumption, 'L')} />
        </Grid>
      </Grid>
    </>
  );
};

const DataAverages = (props) => {
  const {
    dataCompleteness, dataQuality,
  } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={4}
      >

        <Grid item xs={12} md={6} lg={4} xl={2}>
          <ValueBox title="Data Quality" value={formatNumber(dataQuality, '%')} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <ValueBox title="Data Completeness" value={formatNumber(dataCompleteness, '%')} />
        </Grid>
      </Grid>
    </>
  );
};

export const getDelta = (normal, abnormal) => {
  if (normal === null || abnormal === null || normal === undefined || abnormal === undefined) {
    return null;
  }

  return (normal - abnormal);
};

const SummaryCards = (props) => {
  const { summaryData } = props;
  const { t } = useTranslation();
  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <FeedbackPopUp
            header="Performance Metric"
            elementId="performance_metric"
            page="unit_view"
            child={(
              <Tooltip
                placement="right"
                title={`These values show the performance gains of the unit when it was maintained above the ${formatNumber(window.HEALTHINDICATORTHRESHOLD * 100, '%')} health indicator threshold as compared to when it was performing below this threshold`}
              >
                <Typography component="h1" variant="h5" color="primary" gutterBottom>
                  Performance Metrics
                </Typography>
              </Tooltip>
          )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography component="h3" variant="h6" color="textSecondary" gutterBottom>
            {`Health Indicator Threshold = ${formatNumber(window.HEALTHINDICATORTHRESHOLD * 100, '%')}`}
          </Typography>
        </Grid>
      </Grid>

      {(summaryData !== undefined)
        ? (
          <>
            <Grid item xs={12}>
              <MetricAverages
                healthIndicator={summaryData.hi}
                generatorOutput={summaryData.average_generator_output_kwh}
                fuelConsumption={summaryData.average_fuel_consumption_l}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                spacing={4}
              >
                <Grid item xs={6} md={6} lg={4} xl={2}>
                  <HealthCard
                    cardType="normal"
                    efficiency={summaryData.efficiency_normal}
                    fuelConsumption={summaryData.consumption_normal_litres}
                    powerOutput={summaryData.output_normal_kwh}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={4} xl={2}>
                  <HealthCard
                    cardType="abnormal"
                    efficiency={summaryData.efficiency_abnormal}
                    fuelConsumption={summaryData.consumption_abnormal_litres}
                    powerOutput={summaryData.output_abnormal_kwh}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={4} xl={2}>
                  <HealthCard
                    cardType="delta"
                    efficiency={getDelta(summaryData.efficiency_normal, summaryData.efficiency_abnormal)}
                    fuelConsumption={getDelta(summaryData.consumption_normal_litres, summaryData.consumption_abnormal_litres)}
                    powerOutput={getDelta(summaryData.output_normal_kwh, summaryData.output_abnormal_kwh)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FeedbackPopUp
                header="Data Metrics"
                elementId="data-metrics"
                page="unit_view"
                child={(
                  <Tooltip
                    placement="right"
                    title={(
                      <>
                        An overview of the Data Quality for the unit.
                        <ul>
                          <li>
                            <b>Data completeness</b>
                            : is defined by the percentage of data points received out of the expected amount of data points to be received.
                          </li>
                          <li>
                            <b>Data quality</b>
                            : is defined by the number of data points received which do not contain sensor event faults (shown below)."
                          </li>
                        </ul>
                      </>
                    )}
                  >
                    <Typography component="h1" variant="h5" color="primary" gutterBottom>
                      Data Collection Metrics
                    </Typography>
                  </Tooltip>
          )}
              />
            </Grid>
            <Grid item xs={12}>
              <DataAverages
                dataCompleteness={summaryData.average_data_completeness}
                dataQuality={summaryData.average_percent_quality_data}
              />
            </Grid>
          </>
        )
        : (
          <Grid item xs={12}>
            <Typography component="h5" variant="h6" color="textSecondary" gutterBottom>
              {t(translationKeys.typography.insufficientData)}
            </Typography>
          </Grid>
        )}
    </>

  );
};

export default SummaryCards;
