import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import translationKeys from '../../i18n/translationKeys';
import Copyright from '../Copyright';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 1),
    marginTop: 'auto',
    whiteSpace: 'normal',
  },
  warning: {
    color: theme.palette.warning.dark,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <footer className={classes.footer}>
      <Typography align="center" className={classes.warning}>
        {t(translationKeys.appStageInfo.appVersionMessage)}
      </Typography>
      <Typography align="center" className={classes.warning}>
        {t(translationKeys.appStageInfo.feedbackMessage)}
      </Typography>
      <br />
      <Typography align="center" variant="body1">
        {t(translationKeys.typography.poweredBy)}
        {' '}
        {t(translationKeys.typography.cerberus)}
      </Typography>
      <Copyright t={t} />
    </footer>
  );
};

export default Footer;
